import { API_HOST, GINGER_WEB_API } from './constants';
import { uuid } from '../state/models/organization/Survey';

export const B2B_API = {
  CREATE_CSM: `${API_HOST}/b2b/csms`,
  CREATE_ORG: `${API_HOST}/b2b/orgs`,
  GET_ELF_FILE_TEMPLATE: `${API_HOST}/b2b/orgs/utils/templates/elf`,
  GET_PGP_KEY: `${API_HOST}/b2b/sftp-user/pgp-key`,
  USER_INFO: `${API_HOST}/b2b/users/me`,
};

// Ginger Web expects a trailing slash
export const getGingerOrgIdUrl = (orgId: number) => ({
  GET_ORGANIZATION_BY_ID: `${GINGER_WEB_API.url}/headspace_for_work/org/${orgId}/`,
});

export const getB2BOrgIdUrl = (orgId: number, elfId?: string | number) => {
  return {
    ADMINS: `${API_HOST}/b2b/orgs/${orgId}/admins`,
    ADMIN_UPDATE_ORG: `${API_HOST}/b2b/v1/admin/orgs/${orgId}`,
    CSM_UPDATE_ORG: `${API_HOST}/b2b/orgs/${orgId}`,
    DEACTIVATE_MEMBERS: `${API_HOST}/b2b/orgs/${orgId}/members/deactivate`,
    DELETE_ORG: `${API_HOST}/b2b/orgs/${orgId}`,
    GET_CHALLENGES_REPORT: `${API_HOST}/b2b/orgs/${orgId}/challenges/report`,
    GET_ELIGIBILITY_FILE_STATUS_BY_ID: `${API_HOST}/b2b/orgs/${orgId}/eligibility/${elfId}`,
    GET_ENGAGEMENT_REPORT: `${API_HOST}/b2b/org/${orgId}/report`,
    GET_ORGANIZATION_BY_ID: `${API_HOST}/b2b/orgs/${orgId}`,
    GET_SLUG: `${API_HOST}/b2b/org/${orgId}/slug`,
    POST_ROLLOVER: `${API_HOST}/b2b/orgs/${orgId}/rollover`,
    PUT_ORGANIZATION_MARKETING_ATTRIBUTES: `${API_HOST}/b2b/orgs/${orgId}/marketing`,
    UPDATE_ORG_ENROLLMENT: `${API_HOST}/b2b/orgs/${orgId}/enrollment`,
    UPLOAD_ELIGIBILITY_FILE: `${API_HOST}/b2b/org/${orgId}/eligibility/upload`,
    UPLOAD_ORG_LOGO: `${API_HOST}/b2b/org/${orgId}/logo`,
    VERIFICATION_LINK: `${API_HOST}/b2b/org/${orgId}/enroll/verification-link`,
  };
};

export const getB2BOrgUrl = (
  migrationId: number = 0,
  migrationIds: string = '',
) => {
  return {
    GET_ORGANIZATION_BY_ATTRIBUTES: `${API_HOST}/b2b/orgs/single`,
    PREUPDATE_RESTRICT_BY_CHANGE: `${API_HOST}/b2b/orgs/utils/restrict-by-change/preupdate`,
    PREUPDATE_RESTRICT_BY_CHANGE_STATUS: `${API_HOST}/b2b/orgs/utils/restrict-by-change/preupdate/${migrationId}${migrationIds}`,
  };
};

export const getB2BMembersUrl = (orgId: number) => {
  return {
    DB_SEARCH: `${API_HOST}/b2b/orgs/${orgId}/members/search`,
    EXPORT_CSV: `${API_HOST}/b2b/orgs/${orgId}/members/export-csv`,
    SEARCH: `${API_HOST}/b2b/orgs/${orgId}/members/search`,
  };
};

export const getB2BOrgSlugUrl = (orgSlug: string = '') => {
  return {
    CREATE_MEMBER_BY_EMAIL: `${API_HOST}/b2b/org/${orgSlug}/enroll`,
    GET_ORGANIZATIONS: `${API_HOST}/b2b/org/orgs/search${orgSlug}`,
    GET_ADMIN_ORGANIZATIONS: `${API_HOST}/b2b/admin-orgs`,
  };
};

export const getB2BOrgAttributeUrl = (
  attributeName: string,
  attributeValue: string,
) => ({
  GET_ORG_BY_ATTRIBUTE: `${API_HOST}/b2b/org/${attributeValue}?attributeName=${attributeName}`,
});

export const getB2BOrgSubscriptionsUrl = (
  orgId: string | number,
  subscriptionId?: number,
) => {
  return {
    ADD_ORG_SUBSCRIPTIONS: `${API_HOST}/b2b/orgs/${orgId}/subscriptions`,
    UPDATE_ORG_SUBSCRIPTIONS: `${API_HOST}/b2b/orgs/${orgId}/subscriptions/${subscriptionId}`,
    DELETE_ORG_SUBSCRIPTIONS: `${API_HOST}/b2b/orgs/${orgId}/subscriptions/${subscriptionId}`,
  };
};

export const getB2BOrgSFTPUserUrl = (orgId: number, username?: string) => {
  return {
    CREATE_SFTP_USER: `${API_HOST}/b2b/orgs/${orgId}/sftp-user`,
    UPDATE_SFTP_USER: `${API_HOST}/b2b/orgs/${orgId}/sftp-user/${username}`,
    GET_SFTP_USERS: `${API_HOST}/b2b/orgs/${orgId}/sftp-user`,
  };
};

export const getB2BOrgSurveyUrl = (orgId: number, surveyId?: uuid) => {
  return {
    CREATE_SURVEY: `${API_HOST}/b2b/orgs/${orgId}/survey`,
    GET_ORG_SURVEYS: `${API_HOST}/b2b/orgs/${orgId}/survey`,
    SEND_SURVEY: `${API_HOST}/b2b/orgs/${orgId}/survey/${surveyId}/send`,
    DOWNLOAD_SURVEY: `${API_HOST}/b2b/survey/${surveyId}/download-result`,
  };
};

export const getB2BChallengesUrl = (orgId: number, id?: number) => {
  return {
    CREATE_CHALLENGE: `${API_HOST}/b2b/orgs/${orgId}/challenges`,
    GET_CHALLENGES: `${API_HOST}/b2b/orgs/${orgId}/challenges`,
    CANCEL_CHALLENGE: `${API_HOST}/b2b/orgs/${orgId}/challenges/${id}/cancel`,
  };
};

export const getClincalCoverageUrl = (orgId: number) => {
  return {
    CREATE_CLINICAL_COVERAGE: `${API_HOST}/b2b/orgs/${orgId}/clinical-coverage`,
    GET_CLINICAL_COVERAGE: `${API_HOST}/b2b/orgs/${orgId}/clinical-coverage`,
    UPDATE_CLINICAL_COVERAGE: `${API_HOST}/b2b/orgs/${orgId}/clinical-coverage`,
  };
};
