import * as R from 'ramda';

import {
  ORG_CREATION_STEPS,
  PRIMARY_PRODUCT,
} from '../../apps/dashboard/constants';
import { checkIsHsUploadChildElf } from '../../featureFlags/hsUploadChildElf';
import type {
  CLINICAL_COVERAGE_DETAILS,
  RestrictByTypes,
} from '../../types/organization';
import { RestrictBy, CLINICAL_COVERAGE_TYPE } from '../../types/organization';
import { assureNumberValue } from '../../utils/typeMassage';
import { handleEmptyArray, handleNullString } from '../helpers';
import type { OrganizationsState } from '../reducers/organizationsReducer';
import type { RootState } from '../types';
import type {
  ActiveOrgState,
  ManageOrganizationState,
} from '../types/organization';
import { checkFeatureFlag } from '../../utils/checkFeatureFlag';
import { FEATURE_FLAG } from '../../constants/featureFlags';

export const getAdminSaveDisabledStatus = (state: RootState): boolean => {
  const {
    admin_email,
    admin_name,
    admin_emailError,
    admin_nameError,
  } = getOrgManagement(state);
  return (
    admin_nameError?.error ||
    admin_emailError?.error ||
    !admin_name ||
    !admin_email
  );
};

export const getAdminCancelDisabledStatus = (state: RootState): boolean => {
  const { admin_email, admin_name } = getOrgManagement(state);
  return !(!!admin_name || !!admin_email);
};

export const getAdminDeleteDisabledStatus = (state: RootState): boolean => {
  const { adminEditExisting } = getOrgManagement(state);
  // TODO: Expand as logic is determined by product team
  return !adminEditExisting;
};

export const getAddDomainDisabledStatus = (state: RootState): boolean => {
  const { add_domain, add_domainError } = getOrgManagement(state);
  return add_domainError.error || !add_domain;
};

export const getOverflowDisabledStatus = (state: RootState): boolean => {
  const { overflowDisabled } = getOrgManagement(state);

  return overflowDisabled;
};

const isValidFamilyMembersSection = (
  currentContractFamilyMembersPerEmployee: string,
  currentContractNumberOfFamilyMembersSeats: string,
  currentContractNumberOfFamilyMembersSeatsError: ErrorMessage,
): boolean => {
  if (assureNumberValue(currentContractFamilyMembersPerEmployee) === 0) {
    return true;
  }

  return (
    !R.isEmpty(currentContractNumberOfFamilyMembersSeats) &&
    !currentContractNumberOfFamilyMembersSeatsError.error
  );
};

export const getContinueDisabledStatus = (state: RootState): boolean => {
  const {
    currentStep,
    org_nameError,
    org_salesforce_idError,
    org_name,
    org_salesforce_id,
    current_contract_start_date,
    current_contract_start_dateError,
    current_contract_end_date,
    current_contract_end_dateError,
    current_contract_seats,
    current_contract_seatsError,
    current_contract_opportunity_id,
    current_contract_opportunity_idError,
    current_contract_primary_product,
    current_contract_payment_type,
    current_contract_voucher,
    current_contract_family_members_per_employee,
    current_contract_family_members_per_employeeError,
    current_contract_number_of_family_members_seats,
    current_contract_number_of_family_members_seatsError,
    headline,
    headlineError,
    body,
    bodyError,
    overflow_urlError,
    restrictBy,
    restrictedDomains,
    slug,
    slugError,
    workIdFieldPlaceholderText,
    workIdFieldPlaceholderTextError,
    add_domainError,
    parentOrgId,
    parentOrgIdError,
    orgMappingValue,
    orgMappingValueError,
    gingerIdError,
    campaignDisplayNameError,
    campaignLaunchDateAfterError,
    campaignLaunchDateError,
    campaignLaunchDatePastError,
    externalOrgIdError,
    phoneNumberError,
    coverageTotalError,
  } = getOrgManagement(state);
  const hasParent = !!parentOrgId;

  if (currentStep === ORG_CREATION_STEPS.BASIC) {
    return (
      org_nameError.error ||
      !org_name ||
      !current_contract_primary_product ||
      gingerIdError.error ||
      externalOrgIdError.error ||
      phoneNumberError.error ||
      org_salesforce_idError.error ||
      !org_salesforce_id
    );
  }
  if (currentStep === ORG_CREATION_STEPS.ORG_HIERARCHY) {
    const isHsUploadChildElfFeatureFlag = checkIsHsUploadChildElf();

    return (
      parentOrgIdError.error ||
      orgMappingValueError.error ||
      (!isHsUploadChildElfFeatureFlag && hasParent && !orgMappingValue)
    );
  }
  if (currentStep === ORG_CREATION_STEPS.CONTRACT) {
    return (
      !current_contract_start_date ||
      current_contract_start_dateError.error ||
      !current_contract_end_date ||
      current_contract_end_dateError.error ||
      !current_contract_seats ||
      current_contract_seatsError.error ||
      !current_contract_opportunity_id ||
      current_contract_opportunity_idError.error ||
      // If partial pay is selected, a coupon must be selected to continue
      (!!current_contract_payment_type && !current_contract_voucher) ||
      current_contract_family_members_per_employeeError.error ||
      !isValidFamilyMembersSection(
        current_contract_family_members_per_employee,
        current_contract_number_of_family_members_seats,
        current_contract_number_of_family_members_seatsError,
      )
    );
  }
  if (currentStep === ORG_CREATION_STEPS.ENROLLMENT) {
    return (
      campaignDisplayNameError.error ||
      campaignLaunchDateAfterError.error ||
      campaignLaunchDateError.error ||
      campaignLaunchDatePastError.error ||
      !restrictBy ||
      (restrictBy === RestrictBy.EMPLOYEE_ID &&
        (!workIdFieldPlaceholderText ||
          workIdFieldPlaceholderTextError.error)) ||
      (restrictBy === RestrictBy.EMAIL_DOMAIN &&
        add_domainError.error &&
        restrictedDomains.length === 0)
    );
  }
  if (currentStep === ORG_CREATION_STEPS.REVIEW) {
    return (
      !slug ||
      slugError.error ||
      !headline ||
      headlineError.error ||
      !body ||
      bodyError.error ||
      overflow_urlError.error ||
      (restrictBy === RestrictBy.EMPLOYEE_ID && !workIdFieldPlaceholderText) ||
      (restrictBy === RestrictBy.EMPLOYEE_ID &&
        workIdFieldPlaceholderTextError.error) ||
      // If domain verification is selected, a domain must be added to continue
      (restrictBy === RestrictBy.EMAIL_DOMAIN && restrictedDomains.length === 0)
    );
  }
  if (currentStep === ORG_CREATION_STEPS.SUPPLEMENTAL_DETAILS) {
    return (
      externalOrgIdError.error ||
      phoneNumberError.error ||
      coverageTotalError.error
    );
  }
  return false;
};

export const getSettingsChangedStatus = (state: RootState): boolean => {
  const manageOrgState = getOrgManagement(state);
  const { cachedOrg } = manageOrgState;

  return (
    manageOrgState.orgId !== cachedOrg?.id ||
    manageOrgState.org_name !== cachedOrg?.name ||
    manageOrgState.logoFilePreview !== handleNullString(cachedOrg?.logo) ||
    manageOrgState.gingerId !== handleNullString(cachedOrg?.gingerId) ||
    manageOrgState.gingerBundlingEnabled !== cachedOrg?.gingerBundlingEnabled ||
    manageOrgState.org_salesforce_id !==
      handleNullString(cachedOrg?.salesforceId) ||
    manageOrgState.org_type !== handleNullString(cachedOrg?.type) ||
    manageOrgState.sso_enabled !== cachedOrg?.ssoEnabled ||
    manageOrgState.healthHub !== cachedOrg?.healthHub ||
    manageOrgState.healthHubExternalId !== cachedOrg?.healthHubExternalId ||
    JSON.stringify(manageOrgState.org_admins) !==
      JSON.stringify(cachedOrg?.admins) ||
    manageOrgState.slug !== cachedOrg?.enrollment?.slug ||
    manageOrgState.restrictBy !==
      handleNullString(cachedOrg?.enrollment?.restrictBy) ||
    JSON.stringify(manageOrgState.restrictedDomains) !==
      JSON.stringify(
        handleEmptyArray(cachedOrg?.enrollment?.restrictedDomains),
      ) ||
    manageOrgState.headline !==
      handleNullString(cachedOrg?.enrollment.headline) ||
    manageOrgState.body !== handleNullString(cachedOrg?.enrollment?.body) ||
    manageOrgState.learn_more_url_path !==
      handleNullString(cachedOrg?.enrollment?.learnMoreUrl) ||
    manageOrgState.overflow_url !==
      handleNullString(cachedOrg?.enrollment?.overflowUrl) ||
    manageOrgState.overflow_url !==
      handleNullString(cachedOrg?.enrollment?.overflowUrl) ||
    manageOrgState.workIdFieldPlaceholderText !==
      handleNullString(cachedOrg?.enrollment?.workIdFieldPlaceholderText) ||
    manageOrgState.campaignDisplayName !==
      handleNullString(cachedOrg?.enrollment?.campaignDisplayName) ||
    manageOrgState.campaignLaunchDate !==
      handleNullString(cachedOrg?.enrollment?.campaignLaunchDate) ||
    manageOrgState.isSmb !== (cachedOrg?.marketing.isSmb ?? undefined) ||
    manageOrgState.areEnrollmentCommsEnabled !==
      (cachedOrg?.marketing.areEnrollmentCommsEnabled ?? undefined) ||
    manageOrgState.areMemberCommsEnabled !==
      (cachedOrg?.marketing.areMemberCommsEnabled ?? undefined) ||
    manageOrgState.areNonMemberCommsEnabled !==
      (cachedOrg?.marketing.areNonMemberCommsEnabled ?? undefined)
  );
};

export const isOrgHierarchyChanged = (state: RootState): boolean => {
  const manageOrgState = getOrgManagement(state);
  const { cachedOrg } = manageOrgState;

  return (
    manageOrgState.parentOrgId !== handleNullString(cachedOrg?.parentOrgId) ||
    manageOrgState.orgMappingKey !==
      handleNullString(cachedOrg?.orgMappingKey) ||
    manageOrgState.orgMappingValue !==
      handleNullString(cachedOrg?.orgMappingValue)
  );
};

export const getRestrictByChanged = (state: RootState): boolean => {
  const manageOrgState = getOrgManagement(state);
  const { cachedOrg } = manageOrgState;

  return (
    manageOrgState.restrictBy !==
    handleNullString(cachedOrg?.enrollment?.restrictBy)
  );
};

export const getIsRestrictedByELF = (state: RootState): boolean => {
  const { restrictBy } = getOrgManagement(state);

  if (!restrictBy) {
    return false;
  }
  const restrictedByElfTypes = [
    RestrictBy.EMAIL_ELIGIBILITY_FILE,
    RestrictBy.EMPLOYEE_ID,
    RestrictBy.BUNDLED_EMAIL_ELIGIBILITY_FILE,
    RestrictBy.BUNDLED_EMPLOYEE_ID_ELIGIBILITY_FILE,
  ];
  return restrictedByElfTypes.includes(restrictBy);
};

export const getCachedOrg = (state: RootState) =>
  getOrgManagement(state).cachedOrg;

export const getOrgManagement = (state: RootState): ManageOrganizationState =>
  state.manageOrganization;

export const getOrganizations = (state: RootState): OrganizationsState =>
  state.organizations;

export const getOrgCustomWorkIdFieldPlaceholderText = (
  state: RootState,
): string => getOrgManagement(state).workIdFieldPlaceholderText;

export const getActiveOrg = (state: RootState): ActiveOrgState =>
  state.activeOrg;

export const getSFTPUsers = (state: RootState): SFTPUser[] =>
  getOrgManagement(state).sftpUsers;

export const getSFTPFinished = (state: RootState): boolean =>
  getOrgManagement(state).sftpFinished;

export const getActiveSFTPStep = (state: RootState): number =>
  getOrgManagement(state).activeSFTPStep;

export const getMfaEnabled = (state: RootState): boolean =>
  getOrgManagement(state).mfaEnabled;

export const getParentOrgId = (state: RootState): number | null =>
  getOrgManagement(state).parentOrgId;

export const getOrgMappingKey = (state: RootState): string | null =>
  getOrgManagement(state).orgMappingKey;

export const getOrgMappingValue = (state: RootState): string | null =>
  getOrgManagement(state).orgMappingValue;

export const getClinicalCoverageType = (
  state: RootState,
): CLINICAL_COVERAGE_TYPE =>
  getActiveOrg(state)?.clinicalCoverageType ?? CLINICAL_COVERAGE_TYPE.NONE;

export const getClinicalCoverages = (
  state: RootState,
): CLINICAL_COVERAGE_DETAILS[] => getActiveOrg(state)?.clinicalCoverages ?? [];

export const hasClinicalCoverage = (state: RootState): boolean => {
  const clinicalCoverageType = getClinicalCoverageType(state);
  const clinicalCoverages = getClinicalCoverages(state);
  return (
    clinicalCoverageType !== CLINICAL_COVERAGE_TYPE.NONE &&
    clinicalCoverages.length > 0
  );
};

export const isFusionEnabled = (state: RootState): boolean => {
  const {
    cachedOrg: { contracts = [], expiredContracts = [] } = {},
  } = getOrgManagement(state);

  return (
    [...contracts, ...expiredContracts].some(
      (contract) => contract.fusionEnabled,
    ) ?? false
  );
};

export const isUMDEnabled = (state: RootState): boolean => {
  const { cachedOrg: { contracts = [] } = {} } = getOrgManagement(state);

  return (
    [...contracts].some(
      (contract) => contract.primaryProduct === PRIMARY_PRODUCT.UNIFIED,
    ) ?? false
  );
};

export const useChangeOfEnrollmentWithHierarchy = (
  state: RootState,
): boolean => {
  const {
    cachedOrg: {
      enrollment: { restrictBy: oldRestrictBy = undefined } = {},
    } = {},
    childOrgs,
    current_contract_primary_product,
    restrictBy: newRestrictBy,
    rootOrgId,
  } = getOrgManagement(state);
  const withCoeForUnified = checkFeatureFlag(FEATURE_FLAG.B2B_COE_UNIFIED);

  const supportedChangeOfEnrollmentForHierarchy: RestrictByTypes[] = [
    RestrictBy.EMAIL_ELIGIBILITY_FILE,
    RestrictBy.EMPLOYEE_ID,
  ];
  const allowedPrimaryProducts: string[] = [PRIMARY_PRODUCT.FUSION];

  if (withCoeForUnified) allowedPrimaryProducts.push(PRIMARY_PRODUCT.UNIFIED);

  return (
    !!childOrgs?.length &&
    !rootOrgId &&
    !!oldRestrictBy &&
    supportedChangeOfEnrollmentForHierarchy.includes(oldRestrictBy) &&
    supportedChangeOfEnrollmentForHierarchy.includes(newRestrictBy) &&
    allowedPrimaryProducts.includes(current_contract_primary_product)
  );
};
