import { ThunkAction } from '@reduxjs/toolkit';
import type { RootState } from '../../types';
import type { HydrateAction } from '../../types/organization';
import { parseError } from '../../../utils/parseError';
import { ModalActionsTypes } from '../../types/modal';
import { hydrate } from './hydrate';
import {
  OrganizationActionsTypes,
  SaveOrganizationMarketingAction,
} from '../../types/organization';
import { updateOrganizationMarketingConfiguration } from '../../../rest/organization/organization.api';

export const saveOrganizationMarketing = (): ThunkAction<
  Promise<void>,
  RootState,
  void,
  | SaveOrganizationMarketingAction
  | { type: typeof ModalActionsTypes.OPEN_MODAL }
  | HydrateAction
> => {
  return async (dispatch, getState) => {
    const state = getState();

    const {
      manageOrganization: {
        isSmb,
        orgId,
        cachedOrg,
        areEnrollmentCommsEnabled,
        areMemberCommsEnabled,
        areNonMemberCommsEnabled,
      },
    } = state;

    try {
      await updateOrganizationMarketingConfiguration(orgId, {
        areEnrollmentCommsEnabled,
        areMemberCommsEnabled,
        areNonMemberCommsEnabled,
        isSmb,
      });

      dispatch({
        payload: {
          cachedOrg: {
            ...cachedOrg,
            marketing: {
              ...cachedOrg?.marketing,
              areEnrollmentCommsEnabled,
              areMemberCommsEnabled,
              areNonMemberCommsEnabled,
              isSmb,
            },
          },
        },
        type: OrganizationActionsTypes.SAVE_ORGANIZATION_MARKETING,
      });
    } catch (err: any) {
      dispatch({
        payload: {
          message: parseError(err).message,
        },
        type: ModalActionsTypes.OPEN_MODAL,
      });

      if (cachedOrg) dispatch(hydrate(cachedOrg));
    }
  };
};
