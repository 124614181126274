import '@babel/polyfill';
import './assets/fonts.css';
import { PersistGate } from 'redux-persist/integration/react';
import { RouterProvider } from 'react-router-dom';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import * as Sentry from '@sentry/browser';
import { createRoot } from 'react-dom/client';
import { store, persistor } from './state/store';
import { i18n } from './i18n';
import { SENTRY_CONFIG } from './utils/sentryConfig';
import { router } from './root-component/router';

Sentry.init(SENTRY_CONFIG);

const queryClient = new QueryClient();

const root = createRoot(document.getElementById('root') as Element);
root.render(
  <QueryClientProvider client={queryClient}>
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <RouterProvider router={router} />
        </PersistGate>
      </Provider>
    </I18nextProvider>
  </QueryClientProvider>,
);
