import {
  EAP_COVERAGE_TOTAL_DEFAULT,
  EAP_SERVICE_TYPES,
  ORG_CREATION_STEPS,
  PRIMARY_PRODUCT,
  SFTP_STEPS,
} from '../../apps/dashboard/constants';
import {
  CLINICAL_COVERAGE_PROVIDERS,
  RestrictBy,
  CSV_DELIMITER,
  CLINICAL_COVERAGE_TYPE,
} from '../../types/organization';
import { resetError, resetProcessing } from '../../utils/messageDefaults';
import { Action } from '../types';
import {
  ManageOrganizationState,
  OrganizationActionsTypes,
} from '../types/organization';

const {
  PROCESS_ERRORS,
  PROCESS_SIDE_EFFECTS,
  CANCEL_ORGANIZATION_CREATE,
  CANCEL_ORGANIZATION_CHANGES,
  NAVIGATE_TO,
  HANDLE_LOGO_FILE,
  HANDLE_EMAIL_CAMPAIGN_LOGO_FILE,
  HANDLE_ELIGIBILITY_FILE,
  HANDLE_CHANGE,
  HANDLE_UPDATE,
  CLEAR_ADMIN,
  NEW_ADMIN,
  UPDATE_ADMIN,
  CANCEL_UPDATE_ADMIN,
  DELETE_ADMIN,
  CLOSE_DOMAIN_DROPDOWN,
  DOMAIN_DROPDOWN_TOGGLE,
  NEW_DOMAIN,
  DELETE_DOMAIN,
  TOGGLE_EDIT_PANEL_VIEW,
  SAVE_ORGANIZATION_BASIC,
  SAVE_ORGANIZATION_CONTRACT,
  SAVE_ORGANIZATION_ADMINISTRATORS,
  SAVE_ORGANIZATION_ENROLLMENT,
  SAVE_ORGANIZATION_ALL_FIELDS,
  DELETE_ORGANIZATION,
  DELETE_ORGANIZATION_CONTRACT,
  HYDRATE,
  DRAIN,
  CREATE_SFTP_USER,
  FETCH_SFTP_USERS,
  RECEIVE_SFTP_USER,
  UPDATE_SFTP_USER,
  SFTP_FINISHED,
  SET_ACTIVE_SFTP_STEP,
  CLEAR_CAMPAIGN_FIELDS,
} = OrganizationActionsTypes;

export const initialContractFamilyMembersPerEmployeeValue: string = '0';
export const initialTrulyContractFamilyMembersPerEmployeeValue: string = '1';
export const initialContractNumberOfFamilyMembersSeatsValue: string = '';

export const initialState: ManageOrganizationState = {
  ...{
    // UI
    adminEditExisting: false,
    adminReviewView: false,
    currentStep: ORG_CREATION_STEPS.BASIC,
    membersExportButtonsDisabled: [false, false, false],
    overflowDisabled: false,
  },
  ...{
    // MODEL
    activeSFTPStep: +Object.keys(SFTP_STEPS)[0],
    add_domain: '',
    add_domainError: resetError(),
    add_ons: false,
    admin_email: '',
    admin_emailError: resetError(),
    admin_name: '',
    admin_nameError: resetError(),
    admin_title: '',
    admin_titleError: resetError(),
    areEnrollmentCommsEnabled: false,
    areMemberCommsEnabled: false,
    areNonMemberCommsEnabled: false,
    body: '',
    bodyError: resetError(),
    campaignDisplayName: '',
    campaignDisplayNameError: resetError(),
    campaignLaunchDate: '',
    campaignLaunchDateAfterError: resetError(),
    campaignLaunchDateError: resetError(),
    campaignLaunchDatePastError: resetError(),
    childOrgs: [],
    coverageTotal: EAP_COVERAGE_TOTAL_DEFAULT,
    coverageTotalError: resetError(),
    csvDelimiter: CSV_DELIMITER.COMMA,
    current_contract_add_ons: false,
    current_contract_clinical_coverage_type: CLINICAL_COVERAGE_TYPE.NONE,
    current_contract_eligibility_file_based_dependents: false,
    current_contract_end_date: '',
    current_contract_end_dateError: resetError(),
    current_contract_family_members_per_employee: initialContractFamilyMembersPerEmployeeValue,
    current_contract_family_members_per_employeeError: resetError(),
    current_contract_id: null,
    current_contract_invite_based_dependents: false,
    current_contract_is_ebb_enabled: true,
    current_contract_is_pilot: false,
    current_contract_number_of_family_members_seats: initialContractNumberOfFamilyMembersSeatsValue,
    current_contract_number_of_family_members_seatsError: resetError(),
    current_contract_opportunity_id: '',
    current_contract_opportunity_idError: resetError(),
    current_contract_payment_type: '',
    current_contract_primary_product: PRIMARY_PRODUCT.HEADSPACE,
    current_contract_seats: '',
    current_contract_seatsError: resetError(),
    current_contract_start_date: '',
    current_contract_start_dateError: resetError(),
    current_contract_status: '',
    current_contract_voucher: '',
    dependentEnrollmentFlowDisplayName: null,
    eligibilityFile: null,
    eligibilityFileError: resetError(),
    eligibilityFileFormatType: 'CSV',
    eligibilityFileName: '',
    eligibilityFileProcessingMessage: resetProcessing(),
    eligibilityFiles: [],
    externalOrgId: '',
    externalOrgIdError: resetError(),
    future_contract_add_ons: false,
    future_contract_clinical_coverage_type: CLINICAL_COVERAGE_TYPE.NONE,
    future_contract_eligibility_file_based_dependents: false,
    future_contract_end_date: '',
    future_contract_end_dateError: resetError(),
    future_contract_family_members_per_employee: initialContractFamilyMembersPerEmployeeValue,
    future_contract_family_members_per_employeeError: resetError(),
    future_contract_id: null,
    future_contract_invite_based_dependents: false,
    future_contract_is_ebb_enabled: true,
    future_contract_is_pilot: false,
    future_contract_number_of_family_members_seats: initialContractNumberOfFamilyMembersSeatsValue,
    future_contract_number_of_family_members_seatsError: resetError(),
    future_contract_opportunity_id: '',
    future_contract_opportunity_idError: resetError(),
    future_contract_payment_type: '',
    future_contract_primary_product: '',
    emailCampaignLogo: '',
    future_contract_primary_productError: resetError(),
    emailCampaignLogoFile: null,
    future_contract_seats: '',
    emailCampaignLogoFileError: resetError(),
    future_contract_seatsError: resetError(),
    emailCampaignLogoFileName: '',
    future_contract_start_date: '',
    emailCampaignLogoFilePreview: '',
    future_contract_start_dateError: resetError(),
    future_contract_status: '',
    future_contract_voucher: '',
    gingerBundlingEnabled: false,
    gingerId: '',
    gingerIdError: resetError(),
    gingerIdSynced: false,
    headline: '',
    headlineError: resetError(),
    headspaceHealthId: '',
    healthHub: null,
    healthHubExternalId: null,
    healthHubExternalIdError: resetError(),
    isMemberSsoEnabled: false,
    isSmb: false,
    learn_more_url_path: '',
    learn_more_url_pathError: resetError(),
    logo: '',
    logoFile: null,
    logoFileError: resetError(),
    logoFileName: '',
    logoFilePreview: '',
    mfaEnabled: false,
    no_promo_emails: false,
    orgCountrySearch: '',
    orgId: NaN,
    orgMappingKey: null,
    orgMappingValue: null,
    orgMappingValueError: resetError(),
    org_admins: [],
    org_name: '',
    org_nameError: resetError(),
    ssoConnections: [],
    org_salesforce_id: '',
    org_salesforce_idError: resetError(),
    org_type: 'B2B',
    overflow_url: '',
    overflow_urlError: resetError(),
    overlappedCareId: null,
    overlappedCareIdError: resetError(),
    parentOrg: null,
    parentOrgId: null,
    parentOrgIdError: resetError(),
    parentOrgName: null,
    phoneNumber: '',
    phoneNumberError: resetError(),
    pii_enrollment_enabled: false,
    provider: CLINICAL_COVERAGE_PROVIDERS.NONE,
    restrictBy: RestrictBy.EMAIL_ELIGIBILITY_FILE,
    restrictedDomains: [],
    rootOrgId: null,
    serviceType: EAP_SERVICE_TYPES.NONE,
    sftpFinished: false,
    sftpUsers: [],
    slug: 'test-slug',
    slugError: resetError(),
    sso_enabled: false,
    uploadElfAtChildEnabled: false,
    useSingleEnrollmentLink: false,
    workIdFieldPlaceholderText: '',
    workIdFieldPlaceholderTextError: resetError(),
  },
};

export const reducer = (
  state: ManageOrganizationState = initialState,
  action: Action,
): ManageOrganizationState => {
  switch (action.type) {
    case PROCESS_ERRORS:
    case PROCESS_SIDE_EFFECTS:
    case NAVIGATE_TO:
    case HANDLE_LOGO_FILE:
    case HANDLE_EMAIL_CAMPAIGN_LOGO_FILE:
    case HANDLE_ELIGIBILITY_FILE:
    case HANDLE_CHANGE:
    case CLEAR_ADMIN:
    case NEW_ADMIN:
    case UPDATE_ADMIN:
    case CLOSE_DOMAIN_DROPDOWN:
    case DOMAIN_DROPDOWN_TOGGLE:
    case NEW_DOMAIN:
    case DELETE_DOMAIN:
    case TOGGLE_EDIT_PANEL_VIEW:
    case SAVE_ORGANIZATION_BASIC:
    case SAVE_ORGANIZATION_CONTRACT:
    case SAVE_ORGANIZATION_ADMINISTRATORS:
    case SAVE_ORGANIZATION_ENROLLMENT:
    case FETCH_SFTP_USERS:
    case RECEIVE_SFTP_USER:
    case SFTP_FINISHED:
    case SET_ACTIVE_SFTP_STEP:
    case CLEAR_CAMPAIGN_FIELDS:
    case HYDRATE:
      return {
        ...state,
        ...action.payload,
      };
    case CANCEL_UPDATE_ADMIN:
      return {
        ...state,
        ...action.payload,
        org_admins: state.cachedOrg?.admins || state.org_admins,
      };
    case CREATE_SFTP_USER:
    case UPDATE_SFTP_USER:
      return {
        ...state,
        sftpUsers: [action.payload.sftpUser],
      };
    case SAVE_ORGANIZATION_ALL_FIELDS:
    case CANCEL_ORGANIZATION_CREATE:
    case DELETE_ORGANIZATION:
    case DRAIN:
      return { ...initialState };
    case DELETE_ORGANIZATION_CONTRACT:
      return {
        ...state,
        ...action.payload,
      };
    case HANDLE_UPDATE:
    case DELETE_ADMIN:
    case CANCEL_ORGANIZATION_CHANGES:
    default:
      return state;
  }
};
