import type { FunctionComponent } from 'react';
import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import reactHtmlParser from 'react-html-parser';

import { distanceInWords, parseDateUtc } from '../../../../../../utils/dates';
import env from '../../../../../../config/env';
import { Input } from '../../../../../../shared-components/Input';
import { DependentsInputGroup } from '../../../../../../shared-components/DependentsInputGroup';
import { Container } from '../../../../../../shared-components/container/Container';
import { SettingsPanel } from '../shared-components/SettingsPanel/SettingsPanel';
import {
  generateFamilyMembersPerEmployeeOptions,
  isZeroInput,
} from '../../../../utils/manage-organization';
import {
  reviewInputSubtextStyles,
  contractContainerStyle,
  rowStyle,
} from './styles';
import { isCSM as getIsCSM } from '../../../../../../state/selectors/userSelectors';
import {
  getEditFutureContractFormState,
  getFutureContractChangedStatus,
  hasEditFutureContractFormStateErrors,
} from '../../../../../../state/selectors/organization/futureContractSelector/futureContractSelector';
import { getEditCurrentContractFormState } from '../../../../../../state/selectors/organization/currentContractSelector/currentContractSelector';

import {
  handleChange as handleChangeAction,
  toggleEditPanelView,
  updateImplicitState,
  saveOrganizationContract,
  cancelOrganizationChanges,
} from '../../../../../../state/actions/organizationActions';
import {
  ORGANIZATION_FUTURE_CONTRACT_DETAILS,
  VIEW_PANEL_COUNT,
  FUTURE,
  PRIMARY_PRODUCT,
} from '../../../../constants';
import { UIRegularM } from '../../../../../../shared-components/text';
import { getParentOrgId } from '../../../../../../state/selectors/organizationSelectors';
import { PrimaryProductInput } from '../shared-components/PrimaryProductInput/PrimaryProductInput';
import {
  DeleteCallbackTypes,
  ModalActionsTypes,
} from '../../../../../../state/types/modal';
import { useTranslate } from '../../../../../../hooks/useTranslate';
import { useEditFutureContract } from '../../../../../../featureFlags/editFutureContract';
import { handleFutureFamilyMembersPerEmployeeChange } from '../../../../../../state/actions/organizationActions/familyMembers/handleFutureFamilyMembersPerEmployeeChange';
import { AddOnOptionsInput } from '../../../../../../shared-components/AddOnInput/AddOnOptionsInput';
import { CLINICAL_COVERAGE_TYPE } from '../../../../../../types/organization';
import { Contract } from '../../../../types';
import { useFeatureFlag } from '../../../../../../hooks/useFeatureFlag';
import { FEATURE_FLAG } from '../../../../../../constants/featureFlags';

const availableVouchers = env.PAYMENT_TYPE_VOUCHERS;
const locale = 'organizationSettings.contract.';
const { OPEN_MODAL } = ModalActionsTypes;
const { DELETE_FUTURE_CONTRACT } = DeleteCallbackTypes;

export const FutureContractPanel: FunctionComponent = () => {
  const term = FUTURE;
  const { translate } = useTranslate();
  const isCSM = useSelector(getIsCSM);
  const {
    future_contract_eligibility_file_based_dependents,
    future_contract_id,
    future_contract_opportunity_id,
    future_contract_opportunity_idError,
    future_contract_primary_product,
    future_contract_primary_productError,
    future_contract_start_date,
    future_contract_end_date,
    future_contract_seats,
    future_contract_is_ebb_enabled,
    future_contract_is_pilot,
    future_contract_payment_type,
    future_contract_voucher,
    future_contract_start_dateError,
    future_contract_end_dateError,
    future_contract_seatsError,
    future_contract_family_members_per_employee,
    future_contract_family_members_per_employeeError,
    future_contract_number_of_family_members_seats,
    future_contract_number_of_family_members_seatsError,
    viewPanels = new Array(VIEW_PANEL_COUNT).fill(true),
    future_contract_invite_based_dependents,
    future_contract_clinical_coverage_type,
  } = useSelector(getEditFutureContractFormState);

  const contract: Contract = {
    clinicalCoverageType:
      future_contract_clinical_coverage_type ?? CLINICAL_COVERAGE_TYPE.NONE,
    elfDependentsEnabled: future_contract_eligibility_file_based_dependents,
    end_date: future_contract_end_date,
    family_members_per_employee: future_contract_family_members_per_employee,
    fusionEnabled: future_contract_primary_product === PRIMARY_PRODUCT.FUSION,
    id: future_contract_id,
    is_ebb_enabled: future_contract_is_ebb_enabled,
    is_pilot: future_contract_is_pilot,
    number_of_family_members_seats: future_contract_number_of_family_members_seats,
    opportunity_id: future_contract_opportunity_id,
    payment_type: future_contract_payment_type,
    primary_product: future_contract_primary_product,
    seats: future_contract_seats,
    start_date: future_contract_start_date,
    term,
    voucher: future_contract_voucher,
  };

  const {
    current_contract_eligibility_file_based_dependents,
    current_contract_invite_based_dependents,
  } = useSelector(getEditCurrentContractFormState);

  const didFutureContractStateChange = useSelector(
    getFutureContractChangedStatus,
  );
  const hasContractFormErrors = useSelector(
    hasEditFutureContractFormStateErrors,
  );
  const isPanelInView = viewPanels[ORGANIZATION_FUTURE_CONTRACT_DETAILS];
  const hasParentOrgId = !!useSelector(getParentOrgId);
  const isEditable = isCSM && viewPanels.indexOf(false) < 0 && !hasParentOrgId;
  const canEditFutureContract = useEditFutureContract();
  const disableEditingContractDates = canEditFutureContract
    ? false
    : !!future_contract_id;

  const shouldRenderEbbEnabledComponent =
    useFeatureFlag(FEATURE_FLAG.B2B_EBB_ENABLED) &&
    (future_contract_primary_product === PRIMARY_PRODUCT.HEADSPACE ||
      future_contract_primary_product === PRIMARY_PRODUCT.FUSION);

  const dispatch = useDispatch();

  const handleToggleView = useCallback(
    (index) => {
      dispatch(toggleEditPanelView(index));
    },
    [dispatch],
  );

  const handleError = useCallback((e) => dispatch(updateImplicitState(e)), [
    dispatch,
  ]);

  const handleChange = useCallback(
    (event, suppressValidation = undefined, dependantInputType = '') => {
      if (
        event.target.id === 'future_contract_primary_product' &&
        event.target.value !== PRIMARY_PRODUCT.FUSION
      ) {
        dispatch(
          handleChangeAction({
            target: {
              id: 'future_contract_clinical_coverage_type',
              value: CLINICAL_COVERAGE_TYPE.NONE,
            },
          }),
        );
      }
      dispatch(
        handleChangeAction(event, suppressValidation, dependantInputType),
      );
    },
    [dispatch],
  );

  const handleFutureFamilyMembersPerEmployeeChangeCallback = useCallback(
    (event: ExtendedEvent) => {
      dispatch(handleFutureFamilyMembersPerEmployeeChange(event));
    },
    [dispatch],
  );

  const handleSaveContract = useCallback(() => {
    dispatch(saveOrganizationContract(contract));
  }, [contract, dispatch]);

  const handleCancel = useCallback(() => {
    dispatch(cancelOrganizationChanges());
  }, [dispatch]);

  const handleDelete = useCallback(() => {
    dispatch({
      payload: {
        confirmCallback: DELETE_FUTURE_CONTRACT,
        message: `Deleting a future contract is permanent and cannot be undone.`,
        title: `Are you sure you want to delete the future contract?`,
      },
      type: OPEN_MODAL,
    });
  }, [dispatch]);

  useEffect(() => {
    if (future_contract_id === null) {
      handleToggleView(ORGANIZATION_FUTURE_CONTRACT_DETAILS);
    }
  }, [future_contract_id]);

  return (
    <Container>
      <SettingsPanel
        title={translate(`${locale}${term}Title`)}
        view={isPanelInView}
        toggleView={handleToggleView}
        useEditCta={!future_contract_id}
        editCtaLabel={reactHtmlParser(
          translate(`${locale}addContractCta`, { term }),
        )}
        cancelChanges={handleCancel}
        saveChanges={handleSaveContract}
        index={ORGANIZATION_FUTURE_CONTRACT_DETAILS}
        displayEditButton={isEditable}
        settingsChanged={didFutureContractStateChange}
        hasErrors={hasContractFormErrors}
        dataTestId="organization-future-contract-details"
        handleDelete={handleDelete}
        withDeleteButton={!!future_contract_id}
      >
        {(future_contract_id || !isPanelInView) && (
          <div css={contractContainerStyle}>
            <UIRegularM>{translate(`${locale}${term}Subtitle`)}</UIRegularM>
            <div css={rowStyle}>
              <PrimaryProductInput
                contractStateTerm={term}
                showReview={isPanelInView}
                onChange={handleChange}
                disabled={!!future_contract_id}
                errorMessage={future_contract_primary_productError}
              />
              <Input
                type={isPanelInView ? 'review' : 'text'}
                name={`${term}_contract_opportunity_id`}
                placeholder={translate(`${locale}opportunityIdPlaceholder`)}
                label={translate(`${locale}opportunityIdLabel`)}
                value={future_contract_opportunity_id}
                onChange={handleChange}
                onBlur={handleError}
                hasError={future_contract_opportunity_idError.error}
                errorMessage={future_contract_opportunity_idError.message}
                maxLength="18"
              />
            </div>
            <div css={rowStyle}>
              <div>
                <Input
                  type={isPanelInView ? 'review' : 'date'}
                  name={`${term}_contract_start_date`}
                  placeholder={translate(`${locale}datePlaceholder`)}
                  label={translate(`${locale}startLabel`)}
                  value={
                    isPanelInView
                      ? `${future_contract_start_date} ( UTC )`
                      : future_contract_start_date
                  }
                  onChange={(e) =>
                    handleChange(e, undefined, 'future_contract_end_date')
                  }
                  onBlur={handleError}
                  hasError={future_contract_start_dateError.error}
                  errorMessage={future_contract_start_dateError.message}
                  subtext={
                    Date.parse(future_contract_start_date)
                      ? distanceInWords(
                          new Date(),
                          parseDateUtc(future_contract_start_date),
                          true,
                        )
                      : translate('validationErrors.NOT_A_DATE')
                  }
                  disabled={disableEditingContractDates}
                  data-testid="future_contract_start_date"
                />
                {!isPanelInView && (
                  <div css={reviewInputSubtextStyles}>
                    Contract start and end date are in UTC
                  </div>
                )}
              </div>

              <Input
                type={isPanelInView ? 'review' : 'date'}
                name={`${term}_contract_end_date`}
                placeholder={translate(`${locale}datePlaceholder`)}
                label={translate(`${locale}endLabel`)}
                value={
                  isPanelInView
                    ? `${future_contract_end_date} ( UTC )`
                    : future_contract_end_date
                }
                onChange={(e) =>
                  handleChange(e, undefined, 'future_contract_start_date')
                }
                onBlur={handleError}
                hasError={future_contract_end_dateError.error}
                errorMessage={future_contract_end_dateError.message}
                subtext={
                  Date.parse(future_contract_end_date)
                    ? distanceInWords(
                        new Date(),
                        parseDateUtc(future_contract_end_date),
                        true,
                      )
                    : translate('validationErrors.NOT_A_DATE')
                }
                disabled={disableEditingContractDates}
                data-testid="future_contract_end_date"
              />
            </div>
            <div css={rowStyle}>
              <Input
                type={isPanelInView ? 'review' : 'number'}
                name={`${term}_contract_seats`}
                placeholder={translate(`${locale}seatsPlaceholder`)}
                label={translate(`${locale}seatsLabel`)}
                value={future_contract_seats}
                onChange={handleChange}
                onBlur={handleError}
                hasError={future_contract_seatsError.error}
                errorMessage={future_contract_seatsError.message}
              />
              <DependentsInputGroup
                name="dependent-setting"
                inviteBasedDependents={future_contract_invite_based_dependents}
                eligibilityFileBasedDependents={
                  future_contract_eligibility_file_based_dependents
                }
                isViewMode={isPanelInView}
                contract={term}
                isDisabled={
                  current_contract_eligibility_file_based_dependents ||
                  current_contract_invite_based_dependents
                }
              />
            </div>
            {future_contract_invite_based_dependents ? (
              <div data-testid="family-members-section" css={rowStyle}>
                <Input
                  type={isPanelInView ? 'review' : 'select'}
                  name={`${term}_contract_family_members_per_employee`}
                  label={translate(`${locale}familyMembersPerEmployeeLabel`)}
                  onChange={handleFutureFamilyMembersPerEmployeeChangeCallback}
                  value={future_contract_family_members_per_employee}
                  options={generateFamilyMembersPerEmployeeOptions(10)}
                  hasError={
                    future_contract_family_members_per_employeeError?.error
                  }
                  errorMessage={
                    future_contract_family_members_per_employeeError?.message
                  }
                />
                {!isZeroInput(future_contract_family_members_per_employee) && (
                  <Input
                    type={isPanelInView ? 'review' : 'number'}
                    name={`${term}_contract_number_of_family_members_seats`}
                    label={translate(`${locale}familyMembersSeatsLabel`)}
                    placeholder={translate(`${locale}seatsPlaceholder`)}
                    onChange={handleChange}
                    onBlur={handleError}
                    value={future_contract_number_of_family_members_seats}
                    hasError={
                      future_contract_number_of_family_members_seatsError.error
                    }
                    errorMessage={
                      future_contract_number_of_family_members_seatsError.message
                    }
                    data-testid="contract-number-of-family-members-seats-input"
                  />
                )}
              </div>
            ) : null}
            {isCSM && (
              <div css={rowStyle}>
                <Input
                  type={isPanelInView ? 'review' : 'select'}
                  name={`${term}_contract_payment_type`}
                  label={translate(`${locale}paymentLabel`)}
                  value={
                    isPanelInView
                      ? translate(
                          `${locale}paymentOptions.${future_contract_payment_type}`,
                        )
                      : future_contract_payment_type
                  }
                  onChange={handleChange}
                  options={[
                    {
                      title: translate(`${locale}paymentOptions.employerPay`),
                      value: '',
                    },
                    {
                      title: translate(`${locale}paymentOptions.partial`),
                      value: 'partial',
                    },
                  ]}
                />

                {future_contract_payment_type && (
                  <Input
                    type={isPanelInView ? 'review' : 'select'}
                    name={`${term}_contract_voucher`}
                    label={translate(`${locale}voucherCode`)}
                    value={future_contract_voucher}
                    onChange={handleChange}
                    data-testid="contract_voucher"
                    options={availableVouchers.map((currentVoucher: string) => {
                      return { value: currentVoucher };
                    })}
                  />
                )}
              </div>
            )}
            <div css={rowStyle}>
              <Input
                type={isPanelInView ? 'review' : 'checkbox'}
                name={`${term}_contract_is_pilot`}
                label={translate(`${locale}pilotLabel`)}
                value={
                  isPanelInView
                    ? translate(future_contract_is_pilot ? 'yes' : 'no')
                    : future_contract_is_pilot
                }
                onChange={handleChange}
              />
              {shouldRenderEbbEnabledComponent ? (
                <Input
                  type={isPanelInView ? 'review' : 'checkbox'}
                  name={`${term}_contract_is_ebb_enabled`}
                  data-testid={`${term}_contract_is_ebb_enabled`}
                  label={translate(`${locale}ebbLabel`)}
                  value={
                    isPanelInView
                      ? translate(future_contract_is_ebb_enabled ? 'yes' : 'no')
                      : future_contract_is_ebb_enabled
                  }
                  onChange={handleChange}
                />
              ) : null}
            </div>
            <AddOnOptionsInput
              term={term}
              value={future_contract_clinical_coverage_type}
              onChange={handleChange}
              disable={!isCSM || isPanelInView || !contract.fusionEnabled}
            />
          </div>
        )}
      </SettingsPanel>
    </Container>
  );
};
