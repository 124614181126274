import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Divider } from '@headspace/web-ui-components';
import { css } from '@emotion/react';
import { Input } from '../../../../shared-components/Input';
import {
  formContainerStyle,
  rowStyle,
} from '../styles/createOrganizationStyles';
import { handleChange as handleChangeAction } from '../../../../state/actions/organizationActions';
import { useTranslate } from '../../../../hooks/useTranslate';
import { getOrgManagement } from '../../../../state/selectors/organizationSelectors';
import { InputGroup } from '../../../../shared-components/Input/InputGroup';
import { useFeatureFlag } from '../../../../hooks/useFeatureFlag';
import { FEATURE_FLAG } from '../../../../constants/featureFlags';

export const MarketingDetails = () => {
  const dispatch = useDispatch();
  const { translate } = useTranslate();
  const {
    isSmb,
    parentOrgId,
    parentOrg,
    areEnrollmentCommsEnabled,
    areNonMemberCommsEnabled,
    areMemberCommsEnabled,
  } = useSelector(getOrgManagement);

  const isEnabled = useFeatureFlag(
    FEATURE_FLAG.AUTOMATE_SMB_MARKETING_CAMPAIGN,
  );

  const handleChange = useCallback(
    (event: ExtendedEvent) => dispatch(handleChangeAction(event)),
    [dispatch],
  );
  const isSmbParent = parentOrg?.marketing.isSmb;
  const areMemberCommsEnabledOnParent =
    parentOrg?.marketing.areMemberCommsEnabled;
  const areNonMemberCommsEnabledOnParent =
    parentOrg?.marketing.areNonMemberCommsEnabled;
  const areEnrollmentCommsEnabledOnParent =
    parentOrg?.marketing.areEnrollmentCommsEnabled;

  return (
    <div data-testid="marketing-details-page" css={formContainerStyle}>
      <div css={rowStyle}>
        <Input
          type="checkbox"
          dataTestId="isSmb"
          name="isSmb"
          onChange={handleChange}
          value={isSmb || isSmbParent}
          disabled={!!parentOrgId}
          label={translate(
            'organizationSettings.marketingDetails.isSmbEnabled',
          )}
          tabIndex="0"
        />
      </div>

      <Divider />

      <div css={rowStyle}>
        <InputGroup
          name="non-member-comms"
          inputGroupCss={css({
            alignItems: 'left',
            flexDirection: 'column',
          })}
          label={translate(
            'organizationSettings.marketingDetails.nonMemberComms',
          )}
        >
          <Input
            type="checkbox"
            dataTestId="areEnrollmentCommsEnabled"
            name="areEnrollmentCommsEnabled"
            onChange={handleChange}
            value={
              areEnrollmentCommsEnabled || areEnrollmentCommsEnabledOnParent
            }
            disabled={!!parentOrgId || !isEnabled}
            label={translate(
              'organizationSettings.marketingDetails.areEnrollmentCommsEnabled',
            )}
          />
          <Input
            type="checkbox"
            dataTestId="areNonMemberCommsEnabled"
            name="areNonMemberCommsEnabled"
            onChange={handleChange}
            value={areNonMemberCommsEnabled || areNonMemberCommsEnabledOnParent}
            disabled={!!parentOrgId || !isEnabled}
            label={translate(
              'organizationSettings.marketingDetails.areNonMemberCommsEnabled',
            )}
          />
        </InputGroup>

        <InputGroup
          name="member-comms"
          inputGroupCss={css({
            alignItems: 'left',
            flexDirection: 'column',
          })}
          label={translate('organizationSettings.marketingDetails.memberComms')}
        >
          <Input
            type="checkbox"
            dataTestId="areMemberCommsEnabled"
            name="areMemberCommsEnabled"
            onChange={handleChange}
            value={areMemberCommsEnabled || areMemberCommsEnabledOnParent}
            disabled={!!parentOrgId || !isEnabled}
            label={translate(
              'organizationSettings.marketingDetails.areMemberCommsEnabled',
            )}
          />
        </InputGroup>
      </div>
    </div>
  );
};
