import { call, select, put, delay } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { getUnifiedEligibilityRoutes } from '../../../constants/routes';
import { postEnrollMember } from '../../../rest-calls/postMemberEnroll';
import { EnrollmentVerification } from '../../../constants/enrollmentVerification';
import {
  RestrictBy,
  EnrollmentErrors,
} from '../../../../../types/organization';
import { ErrorResponse, parseError } from '../../../../../utils/parseError';
import { clearEnrollmentVerificationConfirmation } from '../../actions/memberEnrollActions/memberEnrollActions';
import { setEnrollmentError } from '../../slice/enrollment/enrollmentReducer';
import { getEnrollMentVerificationSection } from '../../selectors/enrollmentVerificationSelector/enrollmentVerificationSelector';
import {
  getEmail,
  getFirstName,
  getLastName,
} from '../../selectors/memberAuthenticationSelector/memberAuthenticationSelector';
import { getDependentToken } from '../../slice/onboarding/onboardingSelector';
import { postDependentEnroll } from '../../../rest-calls/postDependentEnroll';
import {
  EnrollmentVerificationState,
  KpEnrollmentVerification,
} from '../../reducers/enrollmentVerification/enrollmentVerificationReducer';
import { processMParticleEventSaga } from '../../../../../analytics/mParticleSagas';
import { onboardingDependentAddedEvent } from '../../../../../analytics/events/onboardingEvents';
import { getLanguage } from '../../selectors/languageSelector/languageSelector';
import { router } from '../../../../../root-component/router';
import { getConfig } from '../../../../../config';
import { getExperiment } from '../../selectors/experimentSelector/experimentSelector';
import { getPrimaryProduct } from '../../selectors/orgSectionSelector/orgSectionSelector';
import { PRIMARY_PRODUCT } from '../../../../dashboard/constants';

const EXPERIMENT_VIEW_NAME = 'treatment';

export const SECONDS_DELAY = 1000 * 2;
export function* onBoardingRedirectSaga(
  slug: string,
  orgId: number,
  restrictedBy:
    | RestrictBy.EMAIL_ELIGIBILITY_FILE
    | RestrictBy.EMPLOYEE_ID
    | RestrictBy.KP_NATIONAL_JWT,
  JWT: string,
): SagaIterator {
  const { BYPASS_ONBOARDING_AND_COACHING_ORG_IDS } = yield call(getConfig);
  const enrollmentVerificationSection: EnrollmentVerificationState = yield select(
    getEnrollMentVerificationSection,
  );
  const { verifyIdentity, confirmation } = enrollmentVerificationSection;
  const dependentToken = yield select(getDependentToken);
  const experiment = yield select(getExperiment);
  const primaryProduct = yield select(getPrimaryProduct);

  const {
    ENROLLMENT_LOADING_PATH,
    ONBOARDING_PATH,
    EXPIRED_LINK,
    ERROR_PATH,
    VERIFY_IDENTITY_ERROR_PATH,
    ONBOARDING_NEXT_STEPS_PATH,
  } = getUnifiedEligibilityRoutes(slug);
  router.navigate(ENROLLMENT_LOADING_PATH);
  // Delay to slow down the loading screen
  yield delay(SECONDS_DELAY);

  // Enrolls the member to the organization after login or sign up
  try {
    const preferredCountry = verifyIdentity?.preferredCountryCode;
    const preferredDob = verifyIdentity?.preferredDateOfBirth;

    if (dependentToken) {
      const language = yield select(getLanguage);
      const firstName = yield select(getFirstName);
      const lastName = yield select(getLastName);
      const email = yield select(getEmail);

      yield call(postDependentEnroll, dependentToken, JWT, {
        preferredCountryCode: preferredCountry,
        preferredDateOfBirth: preferredDob,
        firstName,
        lastName,
        hsUserAccountEmail: email,
        locale: language,
      });

      yield call(processMParticleEventSaga, onboardingDependentAddedEvent());
    } else if (confirmation?.method === EnrollmentVerification.PII) {
      const hsUserAccountEmail = yield select(getEmail);
      yield call(postEnrollMember, slug, JWT, {
        enrollmentType: EnrollmentVerification.PII,
        firstName: confirmation?.firstName,
        lastName: confirmation?.lastName,
        preferredDob: confirmation?.dateOfBirth,
        preferredCountry: confirmation?.country,
        zipCode: confirmation?.zipCode,
        hsUserAccountEmail,
      });
    } else if (restrictedBy === RestrictBy.EMPLOYEE_ID && confirmation) {
      const {
        confirmation: { lastName, workId },
      } = yield select(getEnrollMentVerificationSection);

      const hsUserAccountEmail = yield select(getEmail);

      yield call(postEnrollMember, slug, JWT, {
        enrollmentType: EnrollmentVerification.ID,
        hsUserAccountEmail,
        lastName,
        preferredCountry,
        preferredDob,
        workId,
      });
    } else if (
      restrictedBy === RestrictBy.EMAIL_ELIGIBILITY_FILE &&
      confirmation
    ) {
      const {
        confirmation: { verificationToken },
      } = yield select(getEnrollMentVerificationSection);

      const hsUserAccountEmail = yield select(getEmail);

      yield call(postEnrollMember, slug, JWT, {
        enrollmentType: EnrollmentVerification.EMAIL,
        hsUserAccountEmail,
        preferredCountry,
        preferredDob,
        verificationToken,
      });
    } else if (restrictedBy === RestrictBy.KP_NATIONAL_JWT && confirmation) {
      const { kpJwt } = confirmation as KpEnrollmentVerification;

      const hsUserAccountEmail = yield select(getEmail);

      yield call(postEnrollMember, slug, JWT, {
        enrollmentType: EnrollmentVerification.KP_JWT,
        hsUserAccountEmail,
        jwt: kpJwt,
      });
    }

    const isExperimentEnabled =
      BYPASS_ONBOARDING_AND_COACHING_ORG_IDS.includes(orgId) ||
      (experiment === EXPERIMENT_VIEW_NAME &&
        primaryProduct === PRIMARY_PRODUCT.FUSION);

    if (isExperimentEnabled) {
      router.navigate(ONBOARDING_NEXT_STEPS_PATH);
    } else {
      router.navigate(ONBOARDING_PATH);
    }
  } catch (e) {
    const parsedError = parseError(e as ErrorResponse);
    const { errorStringCode, errorCode } = parsedError;
    yield put(setEnrollmentError(parsedError));
    // Invalidate enrollment verification
    yield put(clearEnrollmentVerificationConfirmation());

    // TODO: Create a error generic page do dynamically display error pages
    // https://headspace.atlassian.net/browse/PTNR-6645
    if (errorStringCode === EnrollmentErrors.ENROLLMENT_LINK_EXPIRED) {
      router.navigate(EXPIRED_LINK);
      return;
    }

    if (errorCode?.code === EnrollmentErrors.ALREADY_B2B_SUB) {
      router.navigate(`${VERIFY_IDENTITY_ERROR_PATH}?code=${errorCode?.code}`);
    }

    router.navigate(ERROR_PATH);
  }
}
