import { AuthState } from '@headspace/web-auth/dist/state/rootReducer';
import { CachedOrganization } from '../models/organization/CachedOrganization';
import { UserState } from './user';
import {
  EAP_SERVICE_TYPES,
  ORG_CREATION_STEPS,
  PRIMARY_PRODUCT,
} from '../../apps/dashboard/constants';
import {
  ChildOrganization,
  CLINICAL_COVERAGE_DETAILS,
  CLINICAL_COVERAGE_TYPE,
  EligibilityFile,
  OfferingType,
  Organization,
  RestrictBy,
} from '../../types/organization';
import { Admin } from '../models/organization/Admin';
import { MembersOptionsType } from '../slices/members/getMembers/getMembersThunk';
import { CountryCode } from '../../constants/country';

export type ActiveOrgState = ActiveOrganization | null;
export type ActiveOrgMembersState = OrganizationMembers | null;
export type GetOrgByIdState = number | null;
export type AddMemberByEmailState = { slug: string; email: string } | null;
export type MemberEnrollmentState = { status: number } | null;
export type RemoveMembersState = {
  orgId: number;
  memberIds: MemberIds;
} | null;

export type OrganizationAction =
  | ProcessErrorsAction
  | ProcessSideEffectsAction
  | CancelOrganizationCreateAction
  | NavigateToAction
  | HandleLogoFileInputAction
  | HandleEmailCampaignLogoFileInputAction
  | HandleChangeAction
  | HandleUpdateAction
  | ClearAdminAction
  | DeleteAdminAction
  | SaveAdminAction
  | UpdateAdminAction
  | CancelUpdateAdminAction
  | NewAdminAction
  | NewDomainAction
  | DeleteDomainAction
  | ToggleEditPanelViewAction
  | SaveOrganizationBasicAction
  | SaveOrganizationContractAction
  | SaveOrganizationAdministratorsAction
  | SaveOrganizationEnrollmentAction
  | SaveOrganizationAllFieldsAction
  | CancelOrganizationChangesAction
  | DeleteOrganizationAction
  | HydrateAction
  | DrainAction
  | ActiveOrgMembersAction
  | GetOrgByAttributeAction
  | GetOrgByIdAction
  | AddMemberByEmailAction
  | MemberEnrollmentAction
  | CreateSFTPUserAction
  | FetchSFTPUsersAction
  | ReceiveSFTPUsersAction
  | UpdateSFTPUserAction
  | SetActiveSFTPStepAction;

export enum OrganizationActionsTypes {
  CANCEL_ORGANIZATION_CHANGES = 'CANCEL_ORGANIZATION_CHANGES',
  CANCEL_ORGANIZATION_CREATE = 'CANCEL_ORGANIZATION_CREATE',
  CANCEL_UPDATE_ADMIN = 'CANCEL_ADMIN_UPDATE',
  HANDLE_CHANGE = 'HANDLE_CHANGE',
  HANDLE_LOGO_FILE = 'HANDLE_LOGO_FILE',
  HANDLE_EMAIL_CAMPAIGN_LOGO_FILE = 'HANDLE_EMAIL_CAMPAIGN_LOGO_FILE',
  HANDLE_ELIGIBILITY_FILE = 'HANDLE_ELIGIBILITY_FILE',
  HANDLE_UPDATE = 'HANDLE_UPDATE',
  PROCESS_ERRORS = 'PROCESS_ERRORS',
  PROCESS_SIDE_EFFECTS = 'PROCESS_SIDE_EFFECTS',
  NAVIGATE_TO = 'NAVIGATE_TO',
  CLEAR_ADMIN = 'CLEAR_ADMIN',
  SAVE_ADMIN = 'SAVE_ADMIN',
  DELETE_ADMIN = 'DELETE_ADMIN',
  NEW_ADMIN = 'NEW_ADMIN',
  UPDATE_ADMIN = 'UPDATE_ADMIN',
  DOMAIN_DROPDOWN_TOGGLE = 'DOMAIN_DROPDOWN_TOGGLE',
  CLOSE_DOMAIN_DROPDOWN = 'CLOSE_DOMAIN_DROPDOWN',
  NEW_DOMAIN = 'NEW_DOMAIN',
  DELETE_DOMAIN = 'DELETE_DOMAIN',
  TOGGLE_EDIT_PANEL_VIEW = 'TOGGLE_EDIT_PANEL_VIEW',
  SAVE_ORGANIZATION_BASIC = 'SAVE_ORGANIZATION_BASIC',
  SAVE_ORGANIZATION_CONTRACT = 'SAVE_ORGANIZATION_CONTRACT',
  SAVE_ORGANIZATION_ADMINISTRATORS = 'SAVE_ORGANIZATION_ADMINISTRATORS',
  SAVE_ORGANIZATION_ENROLLMENT = 'SAVE_ORGANIZATION_ENROLLMENT',
  SAVE_ORGANIZATION_MARKETING = 'SAVE_ORGANIZATION_MARKETING',
  SAVE_ORGANIZATION_ALL_FIELDS = 'SAVE_ORGANIZATION_ALL_FIELDS',
  DELETE_ORGANIZATION = 'DELETE_ORGANIZATION',
  DELETE_ORGANIZATION_CONTRACT = 'DELETE_ORGANIZATION_CONTRACT',
  HYDRATE = 'HYDRATE',
  DRAIN = 'DRAIN',
  ORG_SELECTED = 'ORG_SELECTED',
  ORG_MEMBERS_SELECTED = 'ORG_MEMBERS_SELECTED',
  GET_ORG_BY_ATTRIBUTE = 'GET_ORG_BY_ATTRIBUTE',
  GET_ORG_BY_ID = 'GET_ORG_BY_ID',
  ADD_MEMBER_BY_EMAIL = 'ADD_MEMBER_BY_EMAIL',
  MEMBER_ENROLLMENT = 'MEMBER_ENROLLMENT',
  REMOVE_MEMBERS_WITH_SCROLL = 'REMOVE_MEMBERS_WITH_SCROLL',
  REMOVE_MEMBER_FROM_MEMBER_LIST = 'REMOVE_MEMBER_FROM_MEMBER_LIST',
  CREATE_SFTP_USER = 'CREATE_SFTP_USER',
  FETCH_SFTP_USERS = 'FETCH_SFTP_USERS',
  RECEIVE_SFTP_USER = 'RECEIVE_SFTP_USER',
  UPDATE_SFTP_USER = 'UPDATE_SFTP_USER',
  SFTP_FINISHED = 'SFTP_FINISHED',
  SET_ACTIVE_SFTP_STEP = 'SET_ACTIVE_SFTP_STEP',
  CLEAR_CAMPAIGN_FIELDS = 'CLEAR_CAMPAIGN_FIELDS',
}

export interface OrganizationState {
  activeOrg: ActiveOrgState;
  activeOrgMembers: ActiveOrgMembersState;
  getOrgById: GetOrgByIdState;
  addMemberByEmail: AddMemberByEmailState;
  memberEnrollment: MemberEnrollmentState;
  user: UserState;
  manageOrganization: ManageOrganizationState;
  authentication: AuthState;
}

export interface ActiveOrganization {
  id: number;
  name: string;
  logo: string;
  hasSeats: boolean;
  type: string;
  headspaceHealthId: string;
  healthHub?: string;
  healthHubExternalId?: string;
  hasDependentSeats: boolean;
  ssoConnections: string[];
  enrollment: {
    slug: string;
    headline: string;
    body: string;
    learnMoreUrl: string;
    overflowUrl: string;
    restrictBy: RestrictBy;
    restrictedDomains: string[];
    workIdFieldPlaceholderText: string;
  };
  subStarted: boolean;
  subExpired: boolean;
  availableSeatsCount: number;
  partialPayVoucher: string | null;
  hasDependentProgram: boolean;
  dependentsPerMember: number;
  isInGracePeriod?: boolean;
  gingerId: string;
  gingerBundlingEnabled: boolean;
  mfaEnabled: boolean;
  offerings: OfferingType[];
  campaignDisplayName: string | null;
  primaryProduct: PRIMARY_PRODUCT;
  clinicalCoverages: CLINICAL_COVERAGE_DETAILS[];
  clinicalCoverageType: CLINICAL_COVERAGE_TYPE;
}

export interface ManageOrganizationState {
  currentStep: ORG_CREATION_STEPS;
  adminReviewView: boolean;
  adminEditExisting: boolean;
  overflowDisabled: boolean;
  orgId: number;
  headspaceHealthId: string;
  gingerId: string;
  gingerIdSynced: boolean;
  gingerIdError: ErrorMessage;
  gingerBundlingEnabled: boolean;
  overlappedCareId: number | null;
  overlappedCareIdError: ErrorMessage;
  org_name: string;
  org_salesforce_id: string;
  org_type: string;
  orgCountry?: CountryCode;
  orgCountrySearch: string;
  sso_enabled: boolean;
  no_promo_emails: boolean;
  healthHub: HealthHub;
  healthHubExternalId: HealthHubExternalId;
  healthHubExternalIdError: ErrorMessage;
  emailCampaignLogoFileName: string;
  emailCampaignLogo: string;
  emailCampaignLogoFile: File | null;
  emailCampaignLogoFilePreview: string;
  logoFileName: string;
  logo: string;
  logoFile: File | null;
  logoFilePreview: string;
  coverageTotalError: ErrorMessage;
  current_contract_add_ons: boolean;
  current_contract_id: number | null;
  current_contract_start_date: string;
  current_contract_clinical_coverage_type: CLINICAL_COVERAGE_TYPE;
  current_contract_end_date: string;
  current_contract_seats: string;
  current_contract_eligibility_file_based_dependents: boolean;
  current_contract_invite_based_dependents: boolean;
  current_contract_is_ebb_enabled: boolean;
  current_contract_is_pilot: boolean;
  current_contract_payment_type: string;
  current_contract_voucher: string;
  current_contract_opportunity_id: string;
  current_contract_primary_product: string;
  current_contract_family_members_per_employee: string;
  current_contract_number_of_family_members_seats: string;
  current_contract_status: string;
  dependentEnrollmentFlowDisplayName: string | null;
  future_contract_add_ons: boolean;
  future_contract_family_members_per_employee: string;
  future_contract_number_of_family_members_seats: string;
  future_contract_id: number | null;
  future_contract_clinical_coverage_type: CLINICAL_COVERAGE_TYPE;
  future_contract_start_date: string;
  future_contract_end_date: string;
  future_contract_seats: string;
  future_contract_eligibility_file_based_dependents: boolean;
  future_contract_invite_based_dependents: boolean;
  future_contract_is_ebb_enabled: boolean;
  future_contract_is_pilot: boolean;
  future_contract_payment_type: string;
  future_contract_voucher: string;
  future_contract_opportunity_id: string;
  future_contract_primary_product: string;
  future_contract_status: string;
  admin_name: string;
  slug: string;
  restrictBy: RestrictBy;
  headline: string;
  body: string;
  overflow_url: string;
  learn_more_url_path: string;
  restrictedDomains: string[];
  eligibilityFiles: EligibilityFile[];
  eligibilityFileName: string;
  eligibilityFile: File | null;
  eligibilityFileFormatType: string;
  eligibilityFileProcessingMessage: ProcessingMessage;
  add_domain: string;
  slugError: ErrorMessage;
  add_domainError: ErrorMessage;
  overflow_urlError: ErrorMessage;
  learn_more_url_pathError: ErrorMessage;
  headlineError: ErrorMessage;
  bodyError: ErrorMessage;
  org_nameError: ErrorMessage;
  org_salesforce_idError: ErrorMessage;
  admin_title: string;
  admin_email: string;
  logoFileError: ErrorMessage;
  emailCampaignLogoFileError: ErrorMessage;
  current_contract_start_dateError: ErrorMessage;
  current_contract_end_dateError: ErrorMessage;
  current_contract_seatsError: ErrorMessage;
  current_contract_opportunity_idError: ErrorMessage;
  current_contract_family_members_per_employeeError: ErrorMessage;
  current_contract_number_of_family_members_seatsError: ErrorMessage;
  future_contract_number_of_family_members_seatsError: ErrorMessage;
  future_contract_family_members_per_employeeError: ErrorMessage;
  future_contract_start_dateError: ErrorMessage;
  future_contract_end_dateError: ErrorMessage;
  future_contract_seatsError: ErrorMessage;
  future_contract_opportunity_idError: ErrorMessage;
  future_contract_primary_productError: ErrorMessage;
  admin_nameError: ErrorMessage;
  admin_titleError: ErrorMessage;
  admin_emailError: ErrorMessage;
  eligibilityFileError: ErrorMessage;
  org_admins: Admin[];
  viewPanels?: boolean[];
  cachedOrg?: CachedOrganization;
  workIdFieldPlaceholderText: string;
  workIdFieldPlaceholderTextError: ErrorMessage;
  sftpFinished: boolean;
  sftpUsers: SFTPUser[];
  activeSFTPStep: number;
  mfaEnabled: boolean;
  parentOrg: Organization | null;
  parentOrgId: number | null;
  parentOrgIdError: ErrorMessage;
  childOrgs: ChildOrganization[];
  rootOrgId: number | null;
  orgMappingKey: string | null;
  orgMappingValue: string | null;
  orgMappingValueError: ErrorMessage;
  parentOrgName?: string | null;
  campaignDisplayName: string;
  campaignDisplayNameError: ErrorMessage;
  campaignLaunchDate: string;
  campaignLaunchDateAfterError: ErrorMessage;
  campaignLaunchDateError: ErrorMessage;
  campaignLaunchDatePastError: ErrorMessage;
  useSingleEnrollmentLink: boolean;
  uploadElfAtChildEnabled: boolean;
  externalOrgId: string;
  externalOrgIdError: ErrorMessage;
  add_ons: boolean;
  phoneNumber: string;
  phoneNumberError: ErrorMessage;
  provider: string;
  serviceType: EAP_SERVICE_TYPES;
  coverageTotal: number;
  pii_enrollment_enabled?: boolean;
  csvDelimiter: string;
  isMemberSsoEnabled: boolean;
  ssoConnections: {
    connectionName: string;
    connectionIdentifier: string;
    lookupType: string;
  }[];
  // Marketing Configurations
  isSmb: boolean;
  areMemberCommsEnabled: boolean;
  areNonMemberCommsEnabled: boolean;
  areEnrollmentCommsEnabled: boolean;
}

export interface OrganizationAdministratorProps {
  id?: string;
  name: string;
  email: string;
  title?: string;
}

export interface OrganizationContractDependentsValuesToParams {
  dependentsPerMember: number;
  dependentSeats: number;
}

export interface OrganizationContractDependentsParamsToValues {
  dependentsPerMember: string;
  dependentSeats: string;
}

export interface ActiveOrgMembersAction {
  type: string;
  payload: OrganizationMembers;
}

export interface GetOrgByAttributeAction {
  type: OrganizationActionsTypes.GET_ORG_BY_ATTRIBUTE;
  payload: {
    attributeName: 'slug' | 'headspaceHealthId';
    attributeValue: string;
  };
}

export interface GetOrgByIdAction {
  type: string;
  payload: number;
}

export interface RemoveMembersAction {
  type: string;
  payload: {
    orgId: number;
    memberIds: MemberIds;
  };
}

export type removeMembers2PayloadType = {
  orgId: number;
  memberIds: MemberIds;
  options: MembersOptionsType;
  statusModifier?: string;
};

export type removeMembers2ActionType = {
  type: string;
  payload: removeMembers2PayloadType;
};

export interface RemoveMembers2Action {
  (params: removeMembers2PayloadType): removeMembers2ActionType;
}

export interface AddMemberByEmailAction {
  type: string;
  payload: {
    slug: string;
    email: string;
  };
}

export interface MemberEnrollmentAction {
  type: string;
  payload: {
    status: number;
  };
}

export interface ProcessErrorsAction {
  type: OrganizationActionsTypes.PROCESS_ERRORS;
  payload: {
    [id: string]: ErrorMessage;
  };
}

export interface ProcessSideEffectsAction {
  type: OrganizationActionsTypes.PROCESS_SIDE_EFFECTS;
  payload: {
    slug: string;
    headline: string;
    body: string;
  };
}

export interface CancelOrganizationCreateAction {
  type:
    | OrganizationActionsTypes.CANCEL_ORGANIZATION_CREATE
    | OrganizationActionsTypes.HANDLE_UPDATE;
}

export interface NavigateToAction {
  type: OrganizationActionsTypes.NAVIGATE_TO;
  payload: {
    currentStep: ORG_CREATION_STEPS;
  };
}

export interface HandleLogoFileInputAction {
  type: OrganizationActionsTypes.HANDLE_LOGO_FILE;
  payload: {
    logoFileName: string;
    logoFilePreview: string;
    logo: File;
  };
}

export interface HandleEmailCampaignLogoFileInputAction {
  type: OrganizationActionsTypes.HANDLE_EMAIL_CAMPAIGN_LOGO_FILE;
  payload: {
    emailCampaignLogoFileName: string;
    emailCampaignLogoFilePreview: string;
    emailCampaignLogo: File;
  };
}

export interface HandleEligibilityFileInputAction {
  type: OrganizationActionsTypes.HANDLE_ELIGIBILITY_FILE;
  payload: {
    eligibilityFileName?: string;
    eligibilityFileMessage?: string;
    eligibilityFile?: File;
  };
}

export interface HandleChangeAction {
  type: OrganizationActionsTypes.HANDLE_CHANGE;
  payload: {
    [id: string]: string | boolean | number;
  };
}

export interface HandleUpdateAction {
  type: OrganizationActionsTypes.HANDLE_UPDATE;
  payload: null;
}

export interface ClearAdminAction {
  type: OrganizationActionsTypes.CLEAR_ADMIN;
  payload: {
    admin_email: string;
    admin_title: string;
    admin_name: string;
    adminEditExisting: boolean;
  };
}

export interface SaveAdminAction {
  type: OrganizationActionsTypes.SAVE_ADMIN;
  payload: {
    adminReviewView: boolean;
    org_admins: Record<string, unknown>;
  };
}

export interface DeleteAdminAction {
  type: OrganizationActionsTypes.DELETE_ADMIN;
  payload: {
    adminReviewView: boolean;
    org_admins: Record<string, unknown>;
  };
}

export interface CancelUpdateAdminAction {
  type: OrganizationActionsTypes.CANCEL_UPDATE_ADMIN;
  payload: {
    admin_email: string;
    admin_title: string;
    admin_name: string;
    adminEditExisting: boolean;
    adminReviewView: boolean;
  };
}

export interface NewAdminAction {
  type: OrganizationActionsTypes.NEW_ADMIN;
  payload: {
    adminReviewView: boolean;
  };
}

export interface UpdateAdminAction {
  type: OrganizationActionsTypes.UPDATE_ADMIN;
  payload: {
    admin_email: string;
    admin_title: string;
    admin_name: string;
    adminEditExisting: boolean;
    adminReviewView: boolean;
  };
}

export interface SFTPFinishedAction {
  type: OrganizationActionsTypes.SFTP_FINISHED;
  payload: {
    sftpFinished: boolean;
  };
}

export interface CreateSFTPUserAction {
  type: OrganizationActionsTypes.CREATE_SFTP_USER;
  payload: {
    sftpUser: SFTPUser;
  };
}

export interface FetchSFTPUsersAction {
  type: OrganizationActionsTypes.FETCH_SFTP_USERS;
  payload: null;
}

export interface ReceiveSFTPUsersAction {
  type: OrganizationActionsTypes.RECEIVE_SFTP_USER;
  payload: {
    sftpUsers: SFTPUser[];
    sftpFinished: boolean;
  };
}
export interface UpdateSFTPUserAction {
  type: OrganizationActionsTypes.UPDATE_SFTP_USER;
  payload: {
    sftpUser: SFTPUser;
  };
}

export interface SetActiveSFTPStepAction {
  type: OrganizationActionsTypes.SET_ACTIVE_SFTP_STEP;
  payload: {
    activeSFTPStep: number;
  };
}

export interface NewDomainAction {
  type: OrganizationActionsTypes.NEW_DOMAIN;
  payload: {
    restrictedDomains: string[];
    add_domain: string;
    restrictBy: string;
  };
}

export interface DeleteDomainAction {
  type: OrganizationActionsTypes.DELETE_DOMAIN;
  payload: {
    restrictedDomains: string[];
    restrictBy: string;
  };
}

export interface ClearDomainsAction {
  type: OrganizationActionsTypes.DELETE_DOMAIN;
  payload: {
    restrictedDomains: string[];
  };
}

export interface ToggleEditPanelViewAction {
  type: OrganizationActionsTypes.TOGGLE_EDIT_PANEL_VIEW;
  payload: {
    viewPanels: boolean[];
  };
}

export interface SaveOrganizationBasicAction {
  type: OrganizationActionsTypes.SAVE_ORGANIZATION_BASIC;
  payload: {
    // TO DETERMINE
  };
}

export interface SaveOrganizationContractAction {
  type: OrganizationActionsTypes.SAVE_ORGANIZATION_CONTRACT;
  payload: {
    // TO DETERMINE
  };
}

export interface SaveOrganizationAdministratorsAction {
  type: OrganizationActionsTypes.SAVE_ORGANIZATION_ADMINISTRATORS;
  payload: {
    // TO DETERMINE
  };
}

export interface SaveOrganizationEnrollmentAction {
  type: OrganizationActionsTypes.SAVE_ORGANIZATION_ENROLLMENT;
  payload: Record<string, unknown>;
}

export interface SaveOrganizationAllFieldsAction {
  type: OrganizationActionsTypes.SAVE_ORGANIZATION_ALL_FIELDS;
  payload: {
    history: History;
  };
}

export interface CancelOrganizationChangesAction {
  type: OrganizationActionsTypes.CANCEL_ORGANIZATION_CHANGES;
  payload: any;
}

export interface DeleteOrganizationAction {
  type: OrganizationActionsTypes.DELETE_ORGANIZATION;
  payload: null;
}

export interface HydrateAction {
  type: OrganizationActionsTypes.HYDRATE;
  payload: any;
}

export interface DrainAction {
  type: OrganizationActionsTypes.DRAIN;
  payload: Record<string, unknown>;
}

export interface ClearCampaignFieldsAction {
  type: OrganizationActionsTypes.CLEAR_CAMPAIGN_FIELDS;
  payload: {
    campaignDisplayName: string;
    campaignLaunchDate: string;
    campaignDisplayNameError: ErrorMessage;
    campaignLaunchDateAfterError: ErrorMessage;
    campaignLaunchDateError: ErrorMessage;
    campaignLaunchDatePastError: ErrorMessage;
  };
}

export interface SaveOrganizationMarketingAction {
  type: OrganizationActionsTypes.SAVE_ORGANIZATION_MARKETING;
  payload: Record<string, unknown>;
}
