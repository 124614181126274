import { FC, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Divider, Size } from '@headspace/web-ui-components';
import env from '../../../../config/env';
import { DependentsInputGroup } from '../../../../shared-components/DependentsInputGroup';
import { Input } from '../../../../shared-components/Input';
import {
  generateFamilyMembersPerEmployeeOptions,
  isZeroInput,
} from '../../utils/manage-organization';
import { getEditCurrentContractFormState } from '../../../../state/selectors/organization/currentContractSelector/currentContractSelector';

import {
  handleChange as handleChangeAction,
  updateImplicitState,
} from '../../../../state/actions/organizationActions';
import {
  formContainerStyle,
  rowStyle,
} from '../styles/createOrganizationStyles';
import { useTranslate } from '../../../../hooks/useTranslate';
import { handleCurrentFamilyMembersPerEmployeeChange } from '../../../../state/actions/organizationActions/familyMembers/handleCurrentFamilyMembersPerEmployeeChange';
import { PRIMARY_PRODUCT } from '../../constants';
import { AddOnOptionsInput } from '../../../../shared-components/AddOnInput/AddOnOptionsInput';
import { useFeatureFlag } from '../../../../hooks/useFeatureFlag';
import { FEATURE_FLAG } from '../../../../constants/featureFlags';

const availableVouchers = env.PAYMENT_TYPE_VOUCHERS;

export const OrganizationContract: FC = () => {
  const {
    current_contract_primary_product,
    current_contract_opportunity_id,
    current_contract_opportunity_idError,
    current_contract_start_date,
    current_contract_end_date,
    current_contract_seats,
    current_contract_is_ebb_enabled,
    current_contract_is_pilot,
    current_contract_payment_type,
    current_contract_voucher,
    current_contract_start_dateError,
    current_contract_end_dateError,
    current_contract_seatsError,
    current_contract_family_members_per_employee,
    current_contract_family_members_per_employeeError,
    current_contract_number_of_family_members_seats,
    current_contract_number_of_family_members_seatsError,
    current_contract_invite_based_dependents,
    current_contract_eligibility_file_based_dependents,
    current_contract_clinical_coverage_type,
  } = useSelector(getEditCurrentContractFormState);
  const isFusionDisabled =
    current_contract_primary_product !== PRIMARY_PRODUCT.FUSION;
  const { translate } = useTranslate();
  const dispatch = useDispatch();

  const paymentOptions = [
    {
      title: translate(
        'organizationSettings.contract.paymentOptions.employerPay',
      ),
      value: '',
    },
    {
      title: translate('organizationSettings.contract.paymentOptions.partial'),
      value: 'partial',
    },
  ];

  const shouldRenderEbbEnabledComponent =
    useFeatureFlag(FEATURE_FLAG.B2B_EBB_ENABLED) &&
    (current_contract_primary_product === PRIMARY_PRODUCT.HEADSPACE ||
      current_contract_primary_product === PRIMARY_PRODUCT.FUSION);

  const handleChange = useCallback(
    (event, suppressValidation = undefined, dependantInputType = '') => {
      dispatch(
        handleChangeAction(event, suppressValidation, dependantInputType),
      );
    },
    [dispatch],
  );

  const handleError = useCallback(
    (event: ExtendedEvent) => dispatch(updateImplicitState(event)),
    [dispatch],
  );

  const handleCurrentFamilyMembersPerEmployeeChangeCallback = useCallback(
    (event: ExtendedEvent) =>
      dispatch(handleCurrentFamilyMembersPerEmployeeChange(event)),
    [dispatch],
  );

  return (
    <div data-testid="contract-page" css={formContainerStyle}>
      <div css={rowStyle}>
        <Input
          type="text"
          name="current_contract_opportunity_id"
          placeholder={translate(
            'organizationSettings.contract.opportunityIdPlaceholder',
          )}
          label={translate('organizationSettings.contract.opportunityIdLabel')}
          onChange={handleChange}
          onBlur={handleError}
          value={current_contract_opportunity_id}
          hasError={current_contract_opportunity_idError.error}
          errorMessage={current_contract_opportunity_idError.message}
          autoFocus={!current_contract_opportunity_id}
          tabIndex="0"
          maxLength="18"
        />
      </div>
      <div css={rowStyle}>
        <Input
          type="date"
          name="current_contract_start_date"
          label={translate('organizationSettings.contract.startLabel')}
          onChange={(e) =>
            handleChange(e, undefined, 'current_contract_end_date')
          }
          onBlur={handleChange}
          value={current_contract_start_date}
          hasError={current_contract_start_dateError.error}
          errorMessage={current_contract_start_dateError.message}
          tabIndex="0"
        />

        <Input
          type="date"
          name="current_contract_end_date"
          label={translate('organizationSettings.contract.endLabel')}
          onChange={(e) =>
            handleChange(e, undefined, 'current_contract_start_date')
          }
          onBlur={handleChange}
          value={current_contract_end_date}
          hasError={current_contract_end_dateError.error}
          errorMessage={current_contract_end_dateError.message}
          tabIndex="0"
        />
      </div>

      <div css={rowStyle}>
        <AddOnOptionsInput
          term="current"
          value={current_contract_clinical_coverage_type}
          onChange={handleChange}
          disable={isFusionDisabled}
        />
      </div>

      <Divider size={Size.XL} />

      <div css={rowStyle}>
        <Input
          type="number"
          name="current_contract_seats"
          label={translate('organizationSettings.contract.seatsLabel')}
          placeholder={translate(
            'organizationSettings.contract.seatsPlaceholder',
          )}
          onChange={handleChange}
          onBlur={handleError}
          value={current_contract_seats}
          hasError={current_contract_seatsError.error}
          errorMessage={current_contract_seatsError.message}
          tabIndex="0"
        />
        <DependentsInputGroup
          name="dependent-setting"
          inviteBasedDependents={current_contract_invite_based_dependents}
          eligibilityFileBasedDependents={
            current_contract_eligibility_file_based_dependents
          }
          contract="current"
        />
      </div>

      {current_contract_invite_based_dependents ? (
        <div css={rowStyle}>
          <Input
            type="select"
            name="current_contract_family_members_per_employee"
            label={translate(
              'organizationSettings.contract.familyMembersPerEmployeeLabel',
            )}
            onChange={handleCurrentFamilyMembersPerEmployeeChangeCallback}
            value={current_contract_family_members_per_employee}
            options={generateFamilyMembersPerEmployeeOptions(10)}
            hasError={current_contract_family_members_per_employeeError?.error}
            errorMessage={
              current_contract_family_members_per_employeeError?.message
            }
            tabIndex="0"
            data-testid="current_contract_family_members_per_employee"
          />
          {isZeroInput(current_contract_family_members_per_employee) ? null : (
            <Input
              type="number"
              name="current_contract_number_of_family_members_seats"
              label={translate(
                'organizationSettings.contract.familyMembersSeatsLabel',
              )}
              placeholder={translate(
                'organizationSettings.contract.seatsPlaceholder',
              )}
              onChange={handleChange}
              onBlur={handleError}
              value={current_contract_number_of_family_members_seats}
              hasError={
                current_contract_number_of_family_members_seatsError?.error
              }
              errorMessage={
                current_contract_number_of_family_members_seatsError?.message
              }
              data-testid="contract-number-of-family-members-seats-input"
              tabIndex="0"
            />
          )}
        </div>
      ) : null}
      <Divider size={Size.XL} />

      <div css={rowStyle}>
        <Input
          type="select"
          name="current_contract_payment_type"
          label={translate('organizationSettings.contract.paymentLabel')}
          onChange={handleChange}
          value={current_contract_payment_type}
          options={paymentOptions}
        />

        {current_contract_payment_type !== paymentOptions[0].value ? (
          <Input
            type="select"
            name="current_contract_voucher"
            label={translate('organizationSettings.contract.voucherCode')}
            value={current_contract_voucher}
            onChange={handleChange}
            options={availableVouchers.map((voucher: string) => {
              return { value: voucher };
            })}
            data-testid="contract_voucher"
          />
        ) : null}
      </div>
      <Divider size={Size.XL} />

      <Input
        type="checkbox"
        dataTestId="is_pilot_checkbox"
        name="current_contract_is_pilot"
        onChange={handleChange}
        value={current_contract_is_pilot}
        tabIndex="0"
        label={translate('organizationSettings.contract.pilotLabel')}
      />
      {shouldRenderEbbEnabledComponent ? (
        <Input
          type="checkbox"
          dataTestId="is_ebb_enabled_checkbox"
          name="current_contract_is_ebb_enabled"
          onChange={handleChange}
          value={current_contract_is_ebb_enabled}
          tabIndex="0"
          label={translate('organizationSettings.contract.ebbLabel')}
        />
      ) : null}
    </div>
  );
};
