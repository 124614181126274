import { ExperimentActionTypes } from '../../actions/experimentActions/experimentActionsTypes';

interface ExperimentState {
  value?: Record<string, undefined>;
}

const initialState: ExperimentState = {
  value: {},
};

export const experimentReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case ExperimentActionTypes.SET_EXPERIMENT:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
