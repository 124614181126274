import { css } from '@emotion/react';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { rem } from '@headspace/web-ui-components';
import { DetailsPanel } from './components/DetailsPanel/DetailsPanel';
import { ExpiredContractsPanel } from './components/ExpiredContractsPanel/ExpiredContractPanel';
import { AdminsPanel } from './components/AdminsPanel/AdminsPanel';
import { OrganizationSettingsEnrollment } from './components/EnrollmentSettingsPanel/EnrollmentSettingPanel';
import { DeleteOrgPanel } from './components/DeleteOrgPanel/DeleteOrgPanel';
import { EligibilityFilesPanel } from './components/EligibilityFilesPanel/EligibilityFilesPanel';
import { CurrentContractPanel } from './components/CurrentContractPanel/CurrentContractPanel';
import { FutureContractPanel } from './components/FutureContractPanel/FutureContractPanel';
import { SupplementalPanel } from './components/SupplementalPanel/SupplementalPanel';
import { isCSM as getIsCSM } from '../../../../state/selectors/userSelectors';
import {
  getIsRestrictedByELF,
  getOrgManagement,
} from '../../../../state/selectors/organizationSelectors';
import { OrganizationSettingsSFTP } from './components/OrganizationSettingsSFTP';
import { OrgHierarchyPanel } from './components/OrgHierarchyPanel/OrgHierarchyPanel';
import { OrganizationUtilitiesPanel } from './components/OrganizationUtilitiesPanel/OrganizationUtilitiesPanel';
import { useForceRolloverButton } from '../../../../featureFlags/forceRolloverButton';
import { MarketingConfigurationPanel } from './components/MarketingConfigurationPanel/MarketingConfigurationPanel';
import { SsoPanel } from './components/SsoPanel/SsoPanel';

const paddingBottom = css({
  paddingBottom: rem(1.25),
});

export const SettingsView: React.FC = () => {
  const isCSM = useSelector(getIsCSM);
  const isRestrictedByELF = useSelector(getIsRestrictedByELF);
  const {
    parentOrgId,
    childOrgs,
    uploadElfAtChildEnabled,
    isMemberSsoEnabled,
  } = useSelector(getOrgManagement);
  const isForceRolloverEnabled = useForceRolloverButton();

  const hasParentOrgId = !!parentOrgId;
  const hasChildOrgs = childOrgs.length > 0;

  const showElfComponentsAtParent =
    uploadElfAtChildEnabled === false && !hasParentOrgId;

  const showElfComponentsAtChild = uploadElfAtChildEnabled && hasParentOrgId;

  const showElfComponents =
    showElfComponentsAtParent || showElfComponentsAtChild;

  return (
    <div data-testid="settings-view" css={paddingBottom}>
      <div>
        <DetailsPanel />
        {isMemberSsoEnabled && <SsoPanel />}
        <OrgHierarchyPanel />
        <CurrentContractPanel />
        {isCSM && <SupplementalPanel />}
        <FutureContractPanel />
        {isCSM && <ExpiredContractsPanel />}
        <AdminsPanel />
        <OrganizationSettingsEnrollment />
        {isCSM && <MarketingConfigurationPanel />}
        {isCSM && isRestrictedByELF && showElfComponents && (
          <OrganizationSettingsSFTP />
        )}
        {isRestrictedByELF && showElfComponents && <EligibilityFilesPanel />}
        {isCSM && !hasChildOrgs && <DeleteOrgPanel />}
        {isCSM && isForceRolloverEnabled ? (
          <OrganizationUtilitiesPanel />
        ) : null}
      </div>
    </div>
  );
};
