import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ApercuFonts } from '@headspace/web-ui-components';
import {
  Environment,
  Locale,
  WebAnalyticsProvider,
} from '@headspace/web-analytics';
import { dashboardRoutes, MainRoutes } from './Routes';
import { ModalWithState } from './AppModal';
import { appStyle, routeContainerStyleCss } from './styles/appStyle';
import { IntlProvider } from './IntlProvider';
import { useSessionIdleTimer } from '../hooks/useSessionIdleTimer';
import { SessionTimeoutModal } from './components/SessionTimeoutModal/SessionTimeoutModal';
import { ENV } from '../apps/memberEnrollmentV2/constants';
import { useGetCountryCode } from '../hooks/useGetCountryCode';
import { getHsUserId } from '../apps/memberEnrollmentV2/state/selectors/memberAuthenticationSelector/memberAuthenticationSelector';
import { getLanguage } from '../apps/memberEnrollmentV2/state/selectors/languageSelector/languageSelector';
import { headspaceHubRoutes } from '../apps/headspaceHub/constants/routes';
import { useUpdateDocumentTitle } from '../hooks/useUpdateDocumentTitle';
import env from '../config/env';

const App: React.FC = () => {
  useUpdateDocumentTitle('Headspace for Work');
  const { pathname } = useLocation();
  const { activate, getRemainingTime } = useSessionIdleTimer();
  const sdkKey = env.STATSIG_SDK_KEY;
  const sessionTimeoutModalRoutes = [
    ...Object.values(dashboardRoutes),
    ...Object.values(headspaceHubRoutes),
  ];
  const showSessionTimeoutModal = sessionTimeoutModalRoutes.some((a) =>
    pathname.match(a),
  );

  const { data: countryCode } = useGetCountryCode();
  const hsUserId = useSelector(getHsUserId);
  const language = useSelector(getLanguage);

  return (
    <div className="app" css={appStyle(pathname)}>
      <ApercuFonts />
      <div css={routeContainerStyleCss}>
        <WebAnalyticsProvider
          countryCode={countryCode}
          environment={ENV as Environment | undefined}
          initializeExperiment={true} // True because of fusion enrollment experiment
          locale={language as Locale | undefined}
          userId={hsUserId}
          sdkKey={sdkKey}
        >
          <IntlProvider>
            <MainRoutes />
            <ModalWithState />
            {showSessionTimeoutModal && (
              <SessionTimeoutModal
                resetSessionTrackingTimer={activate}
                getRemainingTime={getRemainingTime}
              />
            )}
          </IntlProvider>
        </WebAnalyticsProvider>
      </div>
    </div>
  );
};

export default App;
