import type { FC } from 'react';
import { ChangeEvent, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import { useTranslate } from '../../../../../../../hooks/useTranslate';
import { ChildRestrictedInput } from '../../../../../../../shared-components/ChildRestrictedInput/ChildRestrictedInput';
import {
  getOrgManagement,
  getOrgMappingKey,
} from '../../../../../../../state/selectors/organizationSelectors';

const noop = () => {};

const id = 'orgMappingKey';

interface OrgMappingKeyInputProps {
  editMode?: boolean;
  onChange?: (event: ExtendedEvent) => void;
  optional?: boolean;
  customLabelStyles?: Record<string, unknown>;
}

export const OrgMappingKeyInput: FC<OrgMappingKeyInputProps> = ({
  editMode,
  onChange = noop,
  optional = true,
  customLabelStyles,
}) => {
  const { translate } = useTranslate();
  const orgMappingKey = useSelector(getOrgMappingKey) ?? '';
  const { eligibilityFiles } = useSelector(getOrgManagement);

  const [existingOrgMappingKey] = useState(orgMappingKey);

  const disableMappingKeyInput =
    eligibilityFiles?.length > 0 && existingOrgMappingKey.length > 0;

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      return onChange({
        target: {
          id,
          value: event.target.value === '' ? null : event.target.value,
        },
      });
    },
    [onChange],
  );
  const label = optional
    ? `${translate(
        'organizationSettings.orgHierarchy.orgMappingKey.label',
      )} ${translate('organizationSettings.optional')}`
    : translate('organizationSettings.orgHierarchy.orgMappingKey.label');
  const options: Option[] = [
    {
      disabled: false,
      title: translate('organizationSettings.orgHierarchy.orgMappingKey.none'),
      value: '',
    },
    {
      disabled: false,
      title: translate(
        'organizationSettings.orgHierarchy.orgMappingKey.country',
      ),
      value: 'country',
    },
  ];

  const getInputType = () => {
    if (editMode) return 'text';
    return 'review';
  };

  return (
    <ChildRestrictedInput
      key={id}
      label={label}
      type={getInputType()}
      maxLength={255}
      name={id}
      noLabel={false}
      onChange={handleChange}
      onBlur={noop}
      options={options}
      value={editMode ? orgMappingKey : orgMappingKey || options[0].title}
      data-testid="org-mapping-key-input"
      disabled={disableMappingKeyInput}
      customLabelStyles={customLabelStyles}
    />
  );
};
