export enum RestrictBy {
  UNRESTRICTED = 'UNRESTRICTED',
  EMAIL_ELIGIBILITY_FILE = 'EMAIL_ELIGIBILITY_FILE',
  EMPLOYEE_ID = 'EMPLOYEE_ID',
  EMAIL_DOMAIN = 'EMAIL_DOMAIN',
  SSO = 'SSO',
  BUNDLED_EMAIL_ELIGIBILITY_FILE = 'BUNDLED_EMAIL_ELIGIBILITY_FILE',
  BUNDLED_EMPLOYEE_ID_ELIGIBILITY_FILE = 'BUNDLED_EMPLOYEE_ID_ELIGIBILITY_FILE',
  KP_NATIONAL_JWT = 'KP_NATIONAL_JWT',
}
export type RestrictByTypes = keyof typeof RestrictBy;

export const CAMPAIGN_DISPLAY_SUPPORTED_RESTRICTBY_TYPES: string[] = [
  RestrictBy.EMAIL_ELIGIBILITY_FILE,
  RestrictBy.BUNDLED_EMAIL_ELIGIBILITY_FILE,
  RestrictBy.BUNDLED_EMPLOYEE_ID_ELIGIBILITY_FILE,
  RestrictBy.SSO,
  RestrictBy.EMPLOYEE_ID,
];

export enum OfferingType {
  HEADSPACE = 'HEADSPACE',
  GINGER = 'GINGER',
}

export enum EnrollmentErrors {
  ACTIVE_SUB_VOUCHER_CHANGE = 'ACTIVE_SUB_VOUCHER_CHANGE',
  ELIGIBILITY_FILE_VALIDATION_FAILED = 'ELIGIBILITY_FILE_VALIDATION_FAILED',
  EMPLOYEE_ID_NOT_VERIFIED = 'EMPLOYEE_ID_NOT_VERIFIED',
  EMPTY_EMAIL = 'EMPTY_EMAIL',
  EMPTY_ID = 'EMPTY_ID',
  EMPTY_LAST_NAME = 'EMPTY_LAST_NAME',
  ENROLLMENT_INVALID_DOMAINS = 'ENROLLMENT_INVALID_DOMAINS',
  ENROLLMENT_INVALID_TOKEN = 'ENROLLMENT_INVALID_TOKEN',
  ENROLLMENT_LINK_EXPIRED = 'ENROLLMENT_LINK_EXPIRED',
  GENERIC_ERROR = 'GENERIC_ERROR',
  INVALID_EMAIL = 'INVALID_EMAIL',
  INVALID_SEARCH_KEY = 'INVALID_SEARCH_KEY',
  MEMBER_EMAIL_ENROLLED = 'MEMBER_EMAIL_ENROLLED',
  MEMBER_EMAIL_ENROLLED_IN_OTHER_ORG = 'MEMBER_EMAIL_ENROLLED_IN_OTHER_ORG',
  MEMBER_EMAIL_NOT_ELIGIBLE = 'MEMBER_EMAIL_NOT_ELIGIBLE',
  MEMBER_EMAIL_NOT_WHITELISTED = 'MEMBER_EMAIL_NOT_WHITELISTED',
  MEMBER_ID_MISMATCH = 'MEMBER_ID_MISMATCH',
  NOT_FOUND = 'NOT_FOUND',
  ORG_DELETE_HAS_MEMBERS = 'ORG_DELETE_HAS_MEMBERS',
  ORG_ID_AND_LAST_NAME_ALREADY_TAKEN = 'ORG_ID_AND_LAST_NAME_ALREADY_TAKEN',
  ORG_ID_AND_LAST_NAME_DONT_MATCH = 'ORG_ID_AND_LAST_NAME_DONT_MATCH',
  ORG_ID_NOT_FOUND = 'ORG_ID_NOT_FOUND',
  ORG_NOT_FOUND = 'ORG_NOT_FOUND',
  ORG_NO_SEATS = 'ORG_NO_SEATS',
  ORG_SUB_DATES_INVALID = 'ORG_SUB_DATES_INVALID',
  ORG_SUB_END_DATE_INVALID = 'ORG_SUB_END_DATE_INVALID',
  ORG_SUB_EXPIRED = 'ORG_SUB_EXPIRED',
  ORG_SUB_NOT_STARTED = 'ORG_SUB_NOT_STARTED',
  ORG_SUB_START_DATE_INVALID = 'ORG_SUB_START_DATE_INVALID',
  ORG_TYPE_MISMATCH = 'ORG_TYPE_MISMATCH',
  PARTIAL_PAY_PAYMENT_NOT_FOUND = 'PARTIAL_PAY_PAYMENT_NOT_FOUND',
  PARTIAL_PAY_VOUCHER_NOT_FOUND = 'PARTIAL_PAY_VOUCHER_NOT_FOUND',
  SUB_TOO_FEW_SEATS = 'SUB_TOO_FEW_SEATS',
  UNSUPPORTED_FILE_TYPE = 'UNSUPPORTED_FILE_TYPE',
  WORK_ID_ALREADY_EXISTS = 'WORK_ID_ALREADY_EXISTS',
  ALREADY_B2B_SUB = 'ALREADY_B2B_SUB',
}

export const translatedErrors = Object.keys(EnrollmentErrors);

export enum CLINICAL_COVERAGE_PROVIDERS {
  CCA = 'CCA',
  WPO = 'WPO',
  NONE = '',
}

export enum CSV_DELIMITER {
  COMMA = ',',
  PIPE = '|',
}

export const delimiterOptions = [
  {
    title: 'Comma (",")',
    value: CSV_DELIMITER.COMMA,
  },
  {
    title: 'Pipe ("|")',
    value: CSV_DELIMITER.PIPE,
  },
];

export enum CLINICAL_COVERAGE_TYPE {
  NONE = 'NONE',
  EAP_V2 = 'EAP_2.0',
  INTL_THERAPY = 'INTL_THERAPY',
}

export interface CLINICAL_COVERAGE_DETAILS {
  orgId: number;
  country: string;
  phoneNumber: string;
}

export enum SubscriptionStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export interface Subscription {
  [key: string]: any;
  activeMembers: number;
  clinicalCoverageType: CLINICAL_COVERAGE_TYPE;
  dependentSeats: string;
  dependentsPerMember: string;
  elfDependentsEnabled: boolean;
  endDate: string;
  fusionEnabled: boolean;
  id: string;
  isEbbEnabled: boolean;
  isPilot: boolean;
  offerings: OfferingType[];
  opportunityId: string;
  partialPayVoucher: string;
  seats: string;
  startDate: string;
  status: SubscriptionStatus;
}

export interface Admin {
  email: string;
  name: string;
  title: string;
}

export enum EligibilityFileStatus {
  CANCELLED = 'CANCELLED',
  FAILED = 'FAILED',
  PROCESSED = 'PROCESSED',
  PROCESSING = 'PROCESSING',
  UPLOADED = 'UPLOADED',
  VALIDATING = 'VALIDATING',
  VALIDATION_FAILED = 'VALIDATION_FAILED',
}

export interface EligibilityFile {
  createdAt: string;
  errors: string | null;
  filename: string;
  restrictBy: RestrictBy;
  status: EligibilityFileStatus;
  errorCsvUrl: string | null;
  totalProcessed?: number;
  totalAdded?: number;
  totalRemoved?: number;
  dryRun: boolean;
}

export interface Enrollment {
  slug: string;
  restrictBy: string;
  headline: string;
  body: string;
  learnMoreUrl: string;
  restrictedDomains: string[];
  overflowUrl: string;
  elFiles: EligibilityFile[];
  workIdFieldPlaceholderText?: string;
  campaignDisplayName: string;
  campaignLaunchDate: string;
  dependentEnrollmentFlowDisplayName: string | null;
}

export interface Marketing {
  isSmb: boolean;
  areMemberCommsEnabled: boolean;
  areNonMemberCommsEnabled: boolean;
  areEnrollmentCommsEnabled: boolean;
}

export interface ChildOrganization {
  orgId: number;
  orgMappingValue: string;
  orgName: string;
}

export interface Organization {
  [key: string]: any;
  admins: Admin[];
  childOrgs: ChildOrganization[];
  contracts: Subscription[];
  dependentsPiiEnrollmentEnabled: boolean;
  eligibilityFileFormatType: string;
  enrollment: Enrollment;
  gingerBundlingEnabled: boolean;
  gingerId: string | null;
  hasSeats: boolean;
  headspaceHealthId: string;
  id: number;
  isMemberSsoEnabled: boolean;
  logo?: string;
  logoUpload?: any;
  marketing: Marketing;
  mfaEnabled: boolean;
  name: string;
  orgMappingKey: string | null;
  orgMappingValue: string | null;
  parentOrg: Organization | null;
  parentOrgId: number | null;
  parentOrgName?: string;
  rootOrgId: number | null;
  salesforceId: string;
  subscription?: Subscription;
  type: OrganizationTypes;
}

export enum OrganizationTypes {
  B2B = 'B2B',
  PARTNERSHIP = 'PARTNERSHIP',
  SOCIAL_IMPACT = 'SOCIAL_IMPACT',
  TEST = 'TEST',
  HEALTH_PARTNERSHIP = 'HEALTH_PARTNERSHIP',
  HEALTH = 'HEALTH',
}
