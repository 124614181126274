import { css } from '@emotion/react';
import { SpacingInRemBySize } from '@headspace/web-ui-components';
import { useSelector } from 'react-redux';

import { useTranslate } from '../../../../../../hooks/useTranslate';
import { Container } from '../../../../../../shared-components/container/Container';
import { Panel } from '../../../../../../shared-components/Panel';
import { BasicTable } from '../../../../../../shared-components/tables/BasicTable';
import { BasicTableCell } from '../../../../../../shared-components/tables/BasicTable/BasicTableCell';
import { BasicTableHeaderRow } from '../../../../../../shared-components/tables/BasicTable/BasicTableHeaderRow';
import { BasicTableRow } from '../../../../../../shared-components/tables/BasicTable/BasicTableRow';
import { getOrgManagement } from '../../../../../../state/selectors/organizationSelectors';
import { PanelTitle } from '../shared-components/SettingsPanel/PanelTitle';
import { PanelWrapper } from '../shared-components/SettingsPanel/PanelWrapper';

const locale = 'organizationSettings.sso.';

const reviewTableRowStyles = css({
  padding: SpacingInRemBySize.S,
});

type ComparableSsoConnection = {
  connectionName: string;
  lookupType: string;
};

const compareSsoConnections = (
  a: ComparableSsoConnection,
  b: ComparableSsoConnection,
): number => {
  if (a.lookupType !== b.lookupType) {
    return a.lookupType.localeCompare(b.lookupType);
  }
  return a.connectionName.localeCompare(b.connectionName);
};

export const SsoPanel: React.FC = () => {
  const { translate } = useTranslate();

  const { ssoConnections } = useSelector(getOrgManagement);

  const targetSsoConnections = [...ssoConnections];
  targetSsoConnections.sort(compareSsoConnections);

  return (
    <Container>
      <div>
        <Panel dataTestId="sso-panel">
          <PanelWrapper>
            <PanelTitle>{translate(`${locale}title`)}</PanelTitle>

            <BasicTable>
              <BasicTableHeaderRow>
                <BasicTableCell>
                  {translate(`${locale}connectionNameLabel`)}
                </BasicTableCell>
                <BasicTableCell>
                  {translate(`${locale}connectionIdentifierLabel`)}
                </BasicTableCell>
                <BasicTableCell>
                  {translate(`${locale}lookupTypeLabel`)}
                </BasicTableCell>
              </BasicTableHeaderRow>
              {targetSsoConnections.map((connection) => (
                <BasicTableRow
                  key={connection.connectionName}
                  css={reviewTableRowStyles}
                >
                  <BasicTableCell>{connection.connectionName}</BasicTableCell>
                  <BasicTableCell>
                    {connection.connectionIdentifier}
                  </BasicTableCell>
                  <BasicTableCell>{connection.lookupType}</BasicTableCell>
                </BasicTableRow>
              ))}
            </BasicTable>
          </PanelWrapper>
        </Panel>
      </div>
    </Container>
  );
};
