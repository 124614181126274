import { useEffect, FC } from 'react';
import { css } from '@emotion/react';
import { Routes, Route, Navigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { OnBoardingPage } from './Onboarding/Onboarding';
import { EnrollmentHeader } from '../shared-components/Header';
import { SeatsFullPage } from './ExceptionPages/SeatsFullPage';
import { ContractExpiredPage } from './ExceptionPages/ContractExpiredPage';
import { ExpiredLinkPage } from './ExceptionPages/ExpiredLinkPage';
import { CatchAllErrorPage } from './ExceptionPages/CatchAllErrorPage';
import { LoadingPage } from './LoadingPage/LoadingPage';
import { EnrollmentLoadingPage } from './LoadingPage/EnrollmentLoadingPage';
import { getOrgBySlug } from '../../../../state/actions/organizationActions';
import { SpinnerFullBackGround } from '../../../../shared-components/Spinner/SpinnerFullBackground';
import { EligibilityVerificationPage } from './EligibilityVerificationPage';
import { SignUpPage } from './SignUpPage/SignUpPage';
import { IdentityVerificationPage } from './IdentityVerificationPage';
import { ExpiredDependentLinkPage } from './ExceptionPages/ExpiredDependentLinkPage';
import { IdentityVerificationErrorPage } from './ExceptionPages/IdentityVerificationErrorPage';
import { useUpdateDocumentTitle } from '../../../../hooks/useUpdateDocumentTitle';
import {
  getActiveOrg,
  getIsV3Enrollment,
  getPrimaryProduct,
} from '../../state/selectors/orgSectionSelector/orgSectionSelector';
import { Footer } from '../../../../shared-components/Footer/Footer';
import {
  alignItemsSpaceBetween,
  columnFlex,
  justifyContentSpaceBetween,
} from '../../../dashboard/styles/flex';
import { ExistingAccountPage } from './ExistingAccountPage/ExistingAccountPage';
import { SkipOnboardingFinishPage } from './NextStepsPage/SkipOnboardingFinishPage';
import { PRIMARY_PRODUCT } from '../../../dashboard/constants';
import { getUserIsLoggedIn } from '../../state/selectors/enrollmentVerificationSelector/enrollmentVerificationSelector';

const outerContainerStyle = css(
  columnFlex,
  alignItemsSpaceBetween,
  justifyContentSpaceBetween,
  { height: '100%' },
);

const containerStyle = css({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  width: '100vw',
});

export const UnifiedEligibilityRoutes: FC = () => {
  const dispatch = useDispatch();
  const isV3Enrollment = useSelector(getIsV3Enrollment);
  const primaryProduct = useSelector(getPrimaryProduct);
  const isUserLoggedIn = useSelector(getUserIsLoggedIn);
  const showDownloadBanner =
    primaryProduct === PRIMARY_PRODUCT.FUSION && !isUserLoggedIn;
  const activeOrg = useSelector(getActiveOrg);
  const { slugOrHeadspaceHealthId } = useParams<{
    slugOrHeadspaceHealthId: string;
  }>();

  useUpdateDocumentTitle('Headspace');

  // check if user has been verified - went through the verification steps
  useEffect(() => {
    if (!activeOrg) {
      // @ts-ignore
      dispatch(getOrgBySlug(slugOrHeadspaceHealthId));
    }
  }, [activeOrg, dispatch, slugOrHeadspaceHealthId]);

  if (activeOrg) {
    return isV3Enrollment ? (
      <div css={outerContainerStyle}>
        <EnrollmentHeader
          showStepComponent={false}
          showDownloadBanner={showDownloadBanner}
        />
        <div css={containerStyle}>
          <Routes>
            <Route path="verify" element={<EligibilityVerificationPage />} />
            <Route
              path="verify-identity"
              element={<IdentityVerificationPage />}
            />
            <Route
              path="verify-identity-error"
              element={<IdentityVerificationErrorPage />}
            />
            <Route path="login" element={<SignUpPage />} />
            <Route path="onboarding/*" element={<OnBoardingPage />} />
            <Route path="no-seats" element={<SeatsFullPage />} />
            <Route path="expired-link" element={<ExpiredLinkPage />} />
            <Route
              path="expired-dependent-link"
              element={<ExpiredDependentLinkPage />}
            />
            <Route path="error" element={<CatchAllErrorPage />} />
            <Route path="expired-contract" element={<ContractExpiredPage />} />
            <Route path="loading" element={<LoadingPage />} />
            <Route
              path="enrollment-loading"
              element={<EnrollmentLoadingPage />}
            />
            <Route path="/" element={<EligibilityVerificationPage />} />
            <Route path="existing-account" element={<ExistingAccountPage />} />
            <Route
              path="exit-onboarding"
              element={<SkipOnboardingFinishPage />}
            />
          </Routes>
        </div>
        <Footer />
      </div>
    ) : (
      <Navigate to="/404" replace={true} />
    );
  }

  return <SpinnerFullBackGround />;
};
