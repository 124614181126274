import { useCallback } from 'react';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { css } from '@emotion/react';
import { Divider } from '@headspace/web-ui-components';
import { Container } from '../../../../../../shared-components/container/Container';
import { Input } from '../../../../../../shared-components/Input';
import { SettingsPanel } from '../shared-components/SettingsPanel/SettingsPanel';
import {
  VIEW_PANEL_COUNT,
  ORGANIZATION_MARKETING,
} from '../../../../constants';
import {
  getOrgManagement,
  getSettingsChangedStatus,
} from '../../../../../../state/selectors/organizationSelectors';
import {
  cancelOrganizationChanges,
  handleChange as handleChangeAction,
  updateImplicitState,
  toggleEditPanelView,
} from '../../../../../../state/actions/organizationActions';
import { useTranslate } from '../../../../../../hooks/useTranslate';
import {
  contractContainerStyle,
  rowStyle,
} from '../CurrentContractPanel/styles';
import { useFeatureFlag } from '../../../../../../hooks/useFeatureFlag';
import { FEATURE_FLAG } from '../../../../../../constants/featureFlags';
import { saveOrganizationMarketing } from '../../../../../../state/actions/organizationActions/saveOrganizationMarketing';
import { InputGroup } from '../../../../../../shared-components/Input/InputGroup';

export const MarketingConfigurationPanel: React.FC = () => {
  const dispatch = useDispatch();
  const { translate } = useTranslate();
  const {
    viewPanels = new Array(VIEW_PANEL_COUNT).fill(true),
    isSmb,
    parentOrg,
    areEnrollmentCommsEnabled,
    areNonMemberCommsEnabled,
    areMemberCommsEnabled,
    parentOrgId,
  } = useSelector(getOrgManagement);
  const settingsChanged = useSelector(getSettingsChangedStatus);
  const isPanelInView = viewPanels[ORGANIZATION_MARKETING];
  const isEnabled = useFeatureFlag(
    FEATURE_FLAG.AUTOMATE_SMB_MARKETING_CAMPAIGN,
  );
  const suppressValidations = true;
  const isChild = !!parentOrg?.id;

  // COPY
  const isSmbLabel = translate(
    'organizationSettings.marketingDetails.isSmbEnabled',
  );
  const title = translate('organizationSettings.marketingDetails.title');

  const handleChange = useCallback(
    (e) => dispatch(handleChangeAction(e, suppressValidations)),
    [dispatch, suppressValidations],
  );

  const handleError = useCallback(
    (e) => {
      dispatch(updateImplicitState(e, suppressValidations));
    },
    [dispatch, suppressValidations],
  );

  const handleCancel = useCallback(
    () => dispatch(cancelOrganizationChanges()),
    [dispatch],
  );

  const handleSaveChanges = useCallback(
    () => dispatch(saveOrganizationMarketing()),
    [dispatch],
  );

  const handleToggleView = React.useCallback(
    (index) => {
      dispatch(toggleEditPanelView(index));
    },
    [dispatch],
  );

  const memberCommsEnabledValue =
    areMemberCommsEnabled || parentOrg?.marketing.areMemberCommsEnabled;
  const nonMemberCommsEnabledValue =
    areNonMemberCommsEnabled || parentOrg?.marketing.areNonMemberCommsEnabled;
  const enrollmentCommsEnabledValue =
    areEnrollmentCommsEnabled || parentOrg?.marketing.areEnrollmentCommsEnabled;

  return (
    <Container>
      <SettingsPanel
        title={title}
        view={isPanelInView}
        disabledView={isChild}
        toggleView={handleToggleView}
        cancelChanges={handleCancel}
        saveChanges={handleSaveChanges}
        index={ORGANIZATION_MARKETING}
        dataTestId="marketing-panel"
        displayEditButton={viewPanels.indexOf(false) < 0}
        settingsChanged={settingsChanged}
      >
        <div css={contractContainerStyle}>
          <div css={rowStyle}>
            <Input
              type="checkbox"
              name="isSmb"
              disabled={isPanelInView || !!parentOrgId}
              label={isSmbLabel}
              value={isSmb}
              onChange={handleChange}
              onBlur={handleError}
              data-testid="isSmb"
            />
          </div>
          <Divider />
          <div css={rowStyle}>
            <InputGroup
              name="member-comms"
              inputGroupCss={css({
                alignItems: 'left',
                flexDirection: 'column',
              })}
              label={translate(
                'organizationSettings.marketingDetails.memberComms',
              )}
            >
              <Input
                type="checkbox"
                dataTestId="areEnrollmentCommsEnabled"
                name="areEnrollmentCommsEnabled"
                onChange={handleChange}
                value={enrollmentCommsEnabledValue}
                disabled={!isEnabled || !!parentOrgId || isPanelInView}
                label={translate(
                  'organizationSettings.marketingDetails.areEnrollmentCommsEnabled',
                )}
              />
              <Input
                type="checkbox"
                dataTestId="areNonMemberCommsEnabled"
                name="areNonMemberCommsEnabled"
                onChange={handleChange}
                value={nonMemberCommsEnabledValue}
                disabled={!isEnabled || !!parentOrgId || isPanelInView}
                label={translate(
                  'organizationSettings.marketingDetails.areNonMemberCommsEnabled',
                )}
              />
            </InputGroup>

            <InputGroup
              name="member-comms"
              inputGroupCss={css({
                alignItems: 'left',
                flexDirection: 'column',
              })}
              label={translate(
                'organizationSettings.marketingDetails.memberComms',
              )}
            >
              <Input
                type="checkbox"
                dataTestId="areMemberCommsEnabled"
                name="areMemberCommsEnabled"
                onChange={handleChange}
                value={memberCommsEnabledValue}
                disabled={!isEnabled || !!parentOrgId || isPanelInView}
                label={translate(
                  'organizationSettings.marketingDetails.areMemberCommsEnabled',
                )}
              />
            </InputGroup>
          </div>
        </div>
      </SettingsPanel>
    </Container>
  );
};
